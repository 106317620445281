import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface AutoCompleteProps {
    value?: any;
    onChange?: any;
    label?: string;
    options?: any;
    fullWidth?: boolean;
    size?: string;
    loading?: boolean;
    type?: string;
    hiddeArrow?: boolean;
    onMouseDownCapture?: any;
    disabled?: boolean;
    defaultValue?: any;
    handleInputChange?: any;
    readOnly?: boolean;
    required?: boolean;
    onInputChange?: any;
    templateLabel?: any;
    width?: any;
    name: string;
    errors?: any;
    disableClearable?: any;
    warning?: boolean;
    multiple?: boolean;
    clearPreviousSelection?: boolean;
    testID?: string;
    showClearIcon?: boolean;
}

export default function AutocompleteComponent(props: any) {
    const {
        value,
        onChange,
        label,
        options,
        fullWidth = false,
        size = 'small',
        loading,
        type,
        hiddeArrow = false,
        onMouseDownCapture,
        disabled = false,
        handleInputChange,
        readOnly = false,
        required = false,
        onInputChange,
        templateLabel,
        width = null,
        name,
        errors,
        disableClearable,
        warning,
        multiple = false,
        clearPreviousSelection = false,
        testID,
        showClearIcon = true
    }: AutoCompleteProps = props;

    const handleChange = (_: any, newValue: any) => {
        if (clearPreviousSelection && multiple) {
            if (newValue && newValue.length > 1) {
                newValue = [newValue[newValue.length - 1]];
            }
        }
        if(!newValue){
            onChange('');
            return;
        }
        onChange(newValue || []);
    };

    return (
        <Autocomplete
            clearIcon={showClearIcon ? <HighlightOffIcon color="primary" /> : null}
            value={value || (multiple ? [] : null)}
            disabled={disabled}
            disableClearable={disableClearable}
            options={options || []}
            loading={loading}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option: any) => {
                return templateLabel ? templateLabel(option) : option?.nombre;
            }}
            onChange={handleChange}
            noOptionsText="Sin opciones"
            onMouseDownCapture={onMouseDownCapture}
            freeSolo={hiddeArrow}
            onInputChange={(_, value) => {
                if (onInputChange && value !== 'undefined') return onInputChange(value);
                if (!handleInputChange) return;
                handleInputChange(value);
            }}
            readOnly={readOnly}
            fullWidth={fullWidth}
            multiple={multiple}
            renderOption={(props, option) => {
                return templateLabel ? (
                    <li {...props} key={option.id}>
                        {templateLabel(option)}
                    </li>
                ) : (
                    <li {...props} key={option.id}>
                        {option.nombre}
                    </li>
                );
            }}
            sx={{ width: { width } }}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    id={params.id}
                    label={label}
                    size={size}
                    name={name}
                    sx={{
                        ...(warning && {
                            '& fieldset': {
                                borderColor: '#FABC00 !important',
                                borderWidth: '2px !important'
                            },
                            '& label': {
                                color: '#FABC00 !important'
                            }
                        }),
                        '& .MuiInputLabel-root': {
                            display: 'flex',
                            alignItems: 'center',
                            lineHeight: 3,
                            color: '#476773',
                            fontSize: '16px',
                            fontWeight: 400
                        },
                        '& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled':
                            {
                                transform: 'translate(16px, -16px) scale(0.75)'
                            },
                        '& .MuiInputLabel-asterisk': {
                            color: '#F44336',
                            fontSize: 18
                        }
                    }}
                    helperText={errors && name && errors[name]}
                    error={!!errors && name && !!errors[name]}
                    inputProps={{
                        ...params.inputProps,
                        'data-testid': testID || undefined
                    }}
                    InputProps={{
                        ...params.InputProps,
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                e.stopPropagation();
                            }
                        },
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {type === 'search' ? (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {!params.inputProps.value && (
                                            <SearchIcon color="primary" fontSize="medium" />
                                        )}
                                    </>
                                ) : (
                                    params.InputProps.endAdornment
                                )}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}
