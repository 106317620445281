import React, { useEffect, useState } from 'react';
import {
    Badge,
    Box,
    Breadcrumbs,
    CssBaseline,
    Divider,
    IconButton,
    Stack,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { changeTheme } from '../../features/slices/themeSlice';
import { handleErrors } from '../../utils/common';
import { logout, selectCurrentUser, setTokenFcm } from '../../features/auth/authSlice';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { onMessageListener, fetchToken } from '../../firebase';
import { showAlert } from '../../features/slices/applicationSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useLogoutMutation } from '../../features/auth/authApi';
import { Link, useNavigate } from 'react-router-dom';
import { useRoles } from '../../hooks/useRoles';
import { useSubmitTokenFcmMutation } from '../../services/usersApi';
import { useTranslation } from 'react-i18next';
import AdminLayout from './RoutesManager/AdminLayout';
import AppBar from './AppBar';
import SideMenu from '../SideMenu';
import logoThemeBlue from '../../assets/image/logo-blue.png';
import logoThemeGreen from '../../assets/image/logo-green.png';
import MenuIcon from '@mui/icons-material/Menu';
import ScrollToTop from '../ScrollToTop';
import MenuBarComponent from './MenuAppBar/MenuBarComponent';
import PasswordChangeDrawer from '../PasswordChangeDrawer/PasswordChangeDrawer';
import usePasswordChange from '../PasswordChangeDrawer/usePasswordChange';
import UserCardHeader from './UserCardHeader';
import BackdropComponent from '../Backdrop/BackdropComponent';
import { useTitle } from '../../contexts/TitleContext';
import { useTheme } from '@mui/material/styles';
import useNotifications from '../../pages/HomePrivado/hooks/useNotifications';
import notificationIconBlue from '../../assets/image/NotificationIconHeaderBlue.png';
import notificationIconGreen from '../../assets/image/NotificationIconHeaderGreen.png';

import NotificationsMenuBar from '../../pages/HomePrivado/components/Notifications/NotificationMenuBar';
import { capitalizeFirstLetter } from '../../utils/functions';


type RoleProp = {
    user?: any;
};
const RenderManager = ({ user }: RoleProp) =>
    user ? <AdminLayout /> : <Navigate to="/login" replace />;

const toCapitalized = (str: string): string => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
};
const PrivateLayout = () => {
    const { t } = useTranslation('layoutT');
    const { isMobile, isTablet } = useIsMobile();
    const { role, isAdminBO, isSupervisorSIMP, isOperatorSIMP, isViewerBO } = useRoles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { title } = useTitle();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorNotification, setAnchorNotification] = useState<null | HTMLElement>(null);
    const [checkedIdioma, setCheckedIdioma] = useState(true);
    const [checkedTema, setCheckedTema] = useState('light');
    const [open, setOpen] = useState(false);
    const appbar = document.getElementById('app-bar')?.getBoundingClientRect()!;
    const user: any = useAppSelector(selectCurrentUser);
    const { tokenFcm } = useAppSelector((state) => state?.auth);
    const [isTokenFound, setTokenFound] = useState<boolean>(false);
    const [submitTokenFcm] = useSubmitTokenFcmMutation();
    const [userLogout, { isLoading: loginOut }] = useLogoutMutation();
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'blue');
    
    const {
        notifications,
        setNotifications,
        newNotification,
        setNewNotification,
        openNotificationsMenu,
        notificationsUpdated,
        setOpenNotificationsMenu,
        ...props
    } = useNotifications();

    
    const [pageTitle, setPageTitle] = useState('');
    
    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        if (storedTheme) {
            setTheme(storedTheme);
        }
    }, []);
    const pathnames = location.pathname.split('/').filter(x => x);
    const pathnamesTitle = location.pathname;
    const lastSegment = pathnames[pathnames.length - 1];
    let displayTitleHeader: string;

    const simpTitle = t('simp');

    if (location.pathname === '/simp/home') {
        displayTitleHeader = simpTitle;
    } else if (location.pathname === '/backoffice/home') {
        displayTitleHeader = simpTitle;
    } else {
        displayTitleHeader = '';
    }

    const handleLogOut = async () => {
        const data = {
            username: user?.usuario
        };
        const response: any = await userLogout(data);
        if (response?.error) {
            handleErrors(response?.error);
        } else {
            dispatch(logout());
            navigate('/login');
        }
    };

    useEffect(() => {
        const pathnames = location.pathname.split('/').filter((x) => x);
        const lastSegment = pathnames[pathnames.length - 1];
        
        const capitalizedSegment = lastSegment
            ? lastSegment.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
            : '';

        const title = ` ${capitalizedSegment}`;
        setPageTitle(title);
        document.title = title; 

    }, [location.pathname]);

    const { handleOpenDrawer, ...passwordChangeProps } = usePasswordChange({ handleLogOut });

    useEffect(() => {
        if (!isTokenFound) fetchToken(setTokenFound);
        if (isTokenFound && !tokenFcm) handleSubmitTokenFcm();
        // eslint-disable-next-line
    }, [isTokenFound, tokenFcm]);

    const handleSubmitTokenFcm = async () => {
        const data = { tokenFcm: isTokenFound };
        const response: any = await submitTokenFcm(data);
        if (!response?.error) dispatch(setTokenFcm(data));
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorNotification(event.currentTarget);
        setOpenNotificationsMenu(true);
    };

    const handleCloseNotificationMenu = () => {
        setOpenNotificationsMenu(false);
        setAnchorNotification(null);
    };


    // const handleChangeIdioma = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (checkedIdioma === true) {
    //         i18n.changeLanguage('en');
    //     } else if (checkedIdioma === false) {
    //         i18n.changeLanguage('es');
    //     }
    //     setCheckedIdioma(!checkedIdioma);
    // };

    function containsAllValues(pathname: string, values: string[]): boolean {
        return values.every(value => pathname.includes(value));
      }
    
    const checklastSegmentTitleValue = () => {
        //usar esta función para definir títulos específicos en caso de que el último valor del pathname sea un id
            if(containsAllValues(location.pathname, ['establecimientos', 'presentaciones', 'editar-rar'])){ 
                return 'Detalle Nómina'
            }
            return ''
    }

    const handleDrawerToggle = () => {
        setOpen((open) => !open);
    };

    const logoutHandler = () => {
        dispatch(
            showAlert({
                title: '¿Seguro que desea cerrar sesión?',
                cancelText: 'Cancelar',
                icon: 'info',
                confirmAction: handleLogOut
            })
        );
    };

    // const menuActionManager = (action: any) => {

    //     const { id } = action;

    //     if (id === 1) navigate('/usuarios/perfil'); // Perfil
    //     if (id === 2) handleOpenDrawer(); // Cambiar contraseña
    //     if (id === 3) logoutHandler(); // Logout
    // };

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, to: string) => {
        if (to === '/simp/establecimientos/presentaciones' || 
            to === '/backoffice/establecimientos/presentaciones') {
            event.preventDefault();
            navigate(-1);
        }
    };

    const themeLink = useTheme();
    return (
        <Box
            className="layout-desktop-container container-main"
            display="block"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="100vh"
        >
            <Box minHeight="100vh">
                <ScrollToTop />
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    isMobile={isMobile}
                    id="app-bar"
                    sx={{ overflowX: 'auto', backgroundColor: 'white' }}
                >
                    <Toolbar sx={{ paddingRight: { xs: 0, sm: 2 } }}>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{
                                mr: { sm: 1, md: 2 },
                                display: isMobile ? { sm: 'none' } : open ? 'none' : ''
                            }}
                        >
                            <MenuIcon color="primary" />
                        </IconButton>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ marginLeft: { xs: 0, sm: '3%' } }}
                            flexGrow={1}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    gap: { xs: '6px', sm: '12px' },
                                    margin: '0 3px',
                                    '& img': {
                                        height: { xs: '46px', sm: '50px', md: '56px' }
                                    },
                                    '& .MuiPaper-root': {
                                        boxShadow: 'none'
                                    },
                                    '& hr': {
                                        background: ' var(--primary)',
                                        width: '2px',
                                        height: '40px',
                                        marginTop: '10px'
                                    }
                                }}
                            >
                                <Box>
                                    <img
                                        src={
                                            isAdminBO 
                                                ? logoThemeBlue
                                                : isOperatorSIMP || isSupervisorSIMP || isViewerBO
                                                ? logoThemeGreen
                                                : ''
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            padding: '8px'
                                        }}
                                        onClick={() => {
                                            open && handleDrawerToggle();
                                            navigate('/');
                                        }}
                                    />
                                </Box>
                                <Box sx={{ paddingLeft: '25px' }}>
                                    <div>
                                        <Breadcrumbs
                                            aria-label="breadcrumb"
                                            sx={{
                                                fontSize: '14px',
                                                color: themeLink.palette.primary.light
                                            }}
                                        >
                                            {pathnames.length > 0 ? (
                                                <Link
                                                    to="/"
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'inherit'
                                                    }}
                                                >
                                                    Home
                                                </Link>
                                            ) : (
                                                <Typography color="textPrimary">Home</Typography>
                                            )}

                                            {pathnames.slice(0, -1).map((value, index) => {
                                                if (
                                                    value.toLowerCase() === 'simp' ||
                                                    value.toLowerCase() === 'backoffice'
                                                ) {
                                                    return null;
                                                }

                                                const to = `/${pathnames
                                                    .slice(0, index + 1)
                                                    .join('/')}`;

                                                return (
                                                    <Link
                                                        key={to}
                                                        to={to}
                                                        onClick={(event) => handleClick(event, to)}
                                                        style={{
                                                            textDecoration: 'none',
                                                            color: 'inherit'
                                                        }}
                                                    >
                                                        {capitalizeFirstLetter(value.replace(/-/g, ' '))}
                                                    </Link>
                                                );
                                            })}
                                        </Breadcrumbs>

                                        {lastSegment && lastSegment.toLowerCase() !== 'home' && (
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: '700',
                                                    color: '#1E272E'
                                                    // textTransform: 'capitalize',
                                                }}
                                            >
                                                {lastSegment.toLowerCase() === 'visualizar'  ||
                                                lastSegment.toLowerCase() === 'asignacion-de-nomina' ||
                                                (pathnames[pathnames.length - 2]?.toLowerCase() ===
                                                    'visualizar-rar' &&
                                                    !isNaN(Number(lastSegment)))
                                                    ? capitalizeFirstLetter(title)
                                                    : isNaN(Number(lastSegment))
                                                    ? capitalizeFirstLetter(
                                                          lastSegment.replace(/-/g, ' ')
                                                      )
                                                    : checklastSegmentTitleValue()}
                                            </Typography>
                                        )}

                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: '700',
                                                color: '#1E272E',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {displayTitleHeader}
                                        </Typography>
                                    </div>
                                </Box>
                                <Box></Box>

                                <Box></Box>
                                {/* <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ marginLeft: '1rem' }}
                                >
                                    <Typography variant="caption">EN</Typography>
                                    <Switch
                                        checked={checkedIdioma}
                                        onChange={handleChangeIdioma}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="primary"
                                    />
                                    <Typography variant="caption">ES</Typography>
                                </Stack> */}
                            </Stack>
                            <Stack
                                sx={{
                                    margin: '0 3px',
                                    '& .MuiPaper-root': {
                                        boxShadow: 'none'
                                    },
                                    '& .MuiCard-root': {
                                        marginLeft: 0
                                    }
                                }}
                                direction="row"
                                spacing={3}
                                alignItems="center"
                            >
                                {!isViewerBO && (
                                <IconButton
                                    color="primary"
                                    onClick={handleOpenNotificationsMenu}
                                    sx={{
                                        '& img': {
                                            width: '40px',
                                            borderRadius: '50%',
                                            border:
                                                notifications?.data?.totalUnread > 0
                                                    ? '2px solid'
                                                    : 'none',
                                            borderColor: (theme) => theme.palette.primary.main
                                        },
                                        marginRight: '50px !important'
                                    }}
                                >
                                    <Badge
                                        className={`animation-badge${
                                            notificationsUpdated ? '-scaled' : ''
                                        } badge-primary`}
                                        badgeContent={notifications?.data?.totalUnread}
                                        sx={{
                                            '& .MuiBadge-anchorOriginTopRight': {
                                                fontSize: '15px',
                                                top: '5px',
                                                right: '5px'
                                            }
                                        }}
                                        color="error"
                                    >
                                        {!isTokenFound ? (
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left'
                                                }}
                                                className={
                                                    !isTokenFound ? 'notifications-disabled' : ''
                                                }
                                                invisible={isTokenFound}
                                            >
                                                <img
                                                    src={
                                                        isAdminBO 
                                                            ? notificationIconBlue
                                                            : isOperatorSIMP || isSupervisorSIMP || isViewerBO
                                                            ? notificationIconGreen
                                                            : ''
                                                    }
                                                    alt="notificationIcon"
                                                />
                                            </Badge>
                                        ) : (
                                            <img
                                                src={
                                                    isAdminBO
                                                        ? notificationIconBlue
                                                        : isOperatorSIMP || isSupervisorSIMP || isViewerBO
                                                        ? notificationIconGreen
                                                        : ''
                                                }
                                                alt="notificationIcon"
                                            />
                                        )}
                                    </Badge>
                                </IconButton>
                                )}
                                <UserCardHeader
                                    role={role}
                                    user={user}
                                    handleOpenNavMenu={handleOpenNavMenu}
                                    isMobile={isMobile}
                                />
                                {/* {!isMobile &&
                                    (loginOut ? (
                                        <Loading size="extrasmall" />
                                    ) : (
                                        <IconButton color="primary" onClick={logoutHandler}>
                                            <LogoutIcon />
                                        </IconButton>
                                    ))} */}
                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <SideMenu open={open} isMobile={isMobile} handleDrawerToggle={handleDrawerToggle} />
                <Box
                    component="main"
                    sx={{
                        minHeight: '100vh'
                    }}
                >
                    <Box className="container">
                        <Routes>
                            <Route path="*" element={<RenderManager user={user} />} />
                            <Route path="*" element={<RenderManager />} />
                        </Routes>
                    </Box>
                </Box>
            </Box>
            {/* <MenuBarComponent
                menuActionManager={menuActionManager}
                setAnchorElNav={setAnchorElNav}
                anchorElNav={anchorElNav}
            /> */}

            <NotificationsMenuBar
                isMenu
                openMenu={openNotificationsMenu}
                handleClose={handleCloseNotificationMenu}
                anchorEl={anchorNotification}
                notifications={notifications}
                {...props}
            />

            <PasswordChangeDrawer {...passwordChangeProps} />
            <BackdropComponent loading={loginOut} />
        </Box>
    );
};

export default PrivateLayout;
