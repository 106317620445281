import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SectionHeader from "../SectionHeader";

const DrawerHeader = ({ onIconClick, text }: any) => (
    <Box>
        <SectionHeader containerType="drawer">
            <Grid container>
                <Grid item>
                    <IconButton sx={{mt: .4}} onClick={onIconClick} data-testid={'drawer-icon-button-arrow-back'}>
                        <ArrowBack sx={{ fontSize: 27}} color="primary" data-testid={'drawer-arrow-back-icon'}/>
                    </IconButton>
                </Grid>
                <Grid item xs>
                    <SectionHeader.DrawerTitle>
                        <Typography
                            data-testid={'drawer-title'}
                            variant="h3"
                            sx={{ fontWeight: '700', mb: 0, textAlign: 'left', fontSize: '20px' }}
                        >
                            {text}
                        </Typography>
                    </SectionHeader.DrawerTitle>
                </Grid>
            </Grid>
        </SectionHeader>
    </Box>
);

export {DrawerHeader};