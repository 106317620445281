import React, { useState } from 'react';
import { Box, Divider, List, ListItemAvatar, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { getDateTime } from '../../../../utils/common';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import Loading from '../../../../components/Loading';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close'; 
import styles from './styles.module.css';
import NotificationDrawer from '../../../Notificaciones/components/NotificationDrawer';
import { useTranslation } from 'react-i18next';

function NotificationsList({
    isMenu,
    closeMenu,
    notifications,
    fetchingNotifications,
    loadingNotifications,
    ...props
}: any) {
    const { isMobile, isTablet } = useIsMobile();
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<any>(null); 
    const data = notifications?.data?.data || [];
    const theme = useTheme();

    const handleListItemClick = (item: any) => {
        setSelectedNotification(item); 
        setModalOpen(true); 
    };
    const { t } = useTranslation('communications');

    

    const limitedData = data.slice(0, 10);

    return (
        <>
              
              {isMenu && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '8px',
                        }}
                    >
                        <CloseIcon 
                            onClick={closeMenu} 
                            sx={{ 
                                fontSize: '24px', 
                                cursor: 'pointer' 
                            }}
                        />
                    </Box>
                )}

            <List
                className={styles['list-root']}
                sx={{
                    height: 'auto',
                    maxHeight: '305px',
                    borderRadius: isMenu ? 0 : '25px',
                    '& .MuiListItemText-primary': {
                        color: '#262626',
                        fontWeight: 400,
                        '& div span:first-of-type': {
                            fontSize: '15px',
                            overflow: 'hidden',
                            whiteSpace: isMobile || isTablet || !isMenu ? 'break-spaces' : 'nowrap',
                            maxWidth: isMenu ? '800px' : '460px',
                            minWidth: isMobile ? 'none' : '230px',
                            marginRight: '15px',
                            alignItems: 'center',
                            display: 'flex',
                            lineHeight: isMobile ? '11px' : 'inherit'
                        },
                        '& div span:last-of-type': {
                            fontSize: '14px',
                            minWidth: '120px'
                        }
                    },
                    '& .MuiListItemText-root': {
                        margin: '2px '
                    },
                    '& .MuiListItemAvatar-root': {
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: '40px',
                        alignItems: 'center',
                        '& .MuiSvgIcon-root': {
                            fontSize: '22px'
                        }
                    }
                }}
            >
                {(!isMenu && loadingNotifications) || (isMenu && fetchingNotifications) ? (
                    <Loading size="small" />
                ) : (
                    limitedData.map((item: any) => (
                        <Fragment key={item?.id}>
                            <ListItemButton
                                sx={{
                                    padding: '8px',
                                    marginBottom:'4px',
                                    backgroundColor: !item?.fechaLectura
                                        ? '#F3F8F8'
                                        : 'transparent',
                                    height:
                                        isMobile && isMenu
                                            ? 'auto'
                                            : !isMobile && isMenu
                                            ? '60px'
                                            : '75px'
                                }}
                                onClick={() => handleListItemClick(item)} 
                            >
                                <ListItemAvatar>
                                    {item?.fechaLectura ? (
                                        <NotificationsNoneIcon/>
                                    ) : (
                                        <NotificationsIcon sx={{color: theme.palette.primary.main}}/>
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <div 
                                            className={styles['list-primary-text']}
                                            style={{
                                                fontWeight: !item?.fechaLectura ? 'bold' : 'normal',
                                                color: !item?.fechaLectura ? theme.palette.primary.main : 'inherit'
                                            }}
                                        >
                                            <span>{item?.titulo || ''}</span>
                                            <span>{getDateTime(item.fechaAlta)}</span>
                                        </div>
                                    }
                                />
                            </ListItemButton>
                         
                        </Fragment>
                    ))
                )}
            </List>
         
            {isMenu && limitedData.length > 0 && (
               <Box className="flex-end">
                    <Button 
                        type="text" 
                        onClick={() => {
                            closeMenu(); 
                            navigate('/notificaciones');
                        }}
                    >
                        {t('viewAll')}
                    </Button> 
                </Box>
            )}

            {!loadingNotifications && !fetchingNotifications && limitedData.length === 0 && (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        minHeight: '25vh',  
                        textAlign: 'center' 
                    }}
                >
                    <NotificationsNoneIcon sx={{fontSize: '40px'}}/>
                    <p>{t('notNotification')}</p>
                </Box>
            )}

            <NotificationDrawer
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                data={selectedNotification} 
            />
        </>
    );
}

export default NotificationsList;
