import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { logout, tokenReceived } from '../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});
 
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const nominaApi = createApi({
    reducerPath: 'nominaApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Nomina', 'Empleado', 'Organismo'],
    endpoints: (builder) => ({
        getNomina: builder.query({
            query: ({ cuil, idOrganismo, nombre, apellido, contrato, idEstablecimiento, activo, skip, take }) => ({
                url: 'nomina',
                params: { cuil, idOrganismo, nombre, apellido, contrato, idEstablecimiento, activo, skip, take }
            }),
            providesTags: ['Nomina'],
        }),
        getEmpleados: builder.query({
            query: ({ cuil, idOrganismo }) => ({
                url: 'nomina/empleados',
                params: { cuil, idOrganismo }
            }),
            providesTags: ['Empleado'],
        }),
        getEmpleadosPorOrganismo: builder.query({
            query: ({ idOrganismo }) => ({
                url: `nomina/organismos/${idOrganismo}`,
            }),
            providesTags: ['Organismo'],
        }),
        assignEstablecimientoToEmpleados: builder.mutation({
            query: ({ idEstablecimiento, idsEmpleados }) => ({
                url: `nomina/establecimiento/${idEstablecimiento}/empleados`,
                method: 'POST',
                body: { idsEmpleados },
            }),
            invalidatesTags: ['Nomina'],
        }),
    }),
});

export const {
    useGetNominaQuery,
    useGetEmpleadosQuery,
    useGetEmpleadosPorOrganismoQuery,
    useAssignEstablecimientoToEmpleadosMutation,
} = nominaApi;
