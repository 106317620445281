import { Chip } from '@mui/material';
import { PRESENTATION_STATE } from '../consts';

interface PresentationStateChipProps {
    presentationState: PRESENTATION_STATE;
}

const presentationStateChipMap = () => ({
    [PRESENTATION_STATE.BORRADOR]: {
        textColor: '#1E272E',
        backgorundColor: '#E0EBED',
        text: 'Borrador'
    },
    [PRESENTATION_STATE.RECIBIDO]: {
        textColor: '#FF9900',
        backgorundColor: '#FFFBEA',
        text: 'Recibido'
    },
    [PRESENTATION_STATE.EN_REVISION]: {
        textColor: '#175AB6',
        backgorundColor: '#D9F1FF',
        text: 'En revisión'
    },
    [PRESENTATION_STATE.PENDIENTE_VISITA]: {
        textColor: '#904CE7',
        backgorundColor: '#DFDBF4',
        text: 'Pendiente Visita'
    },
    [PRESENTATION_STATE.PRESENTADO]: {
        textColor: '#2D6830',
        backgorundColor: '#E3F5E3',
        text: 'Presentado'
    },
    [PRESENTATION_STATE.CANCELADO]: {
        textColor: '#F44336',
        backgorundColor: '#FEF3F2',
        text: 'Rechazado'
    },
    [PRESENTATION_STATE.PROCESANDO]: {
        textColor: '#8C8C8C',
        backgorundColor: '#EEEEEE',
        text: 'Procesando'
    },
    [PRESENTATION_STATE.ERROR_PROCESAMIENTO]: {
        textColor: '#F44336',
        backgorundColor: '#FEF3F2',
        text: 'Error'
    }
});

const PresentationStateChip = ({ presentationState }: PresentationStateChipProps) => {
    const chip = presentationStateChipMap()[presentationState as PRESENTATION_STATE] || {
        textColor: 'white',
        backgorundColor: 'gray',
        text: '-'
    };

    const { textColor, backgorundColor, text } = chip;

    return (
        <Chip
            label={text}
            sx={{
                backgroundColor: backgorundColor,
                color: textColor
            }}
        ></Chip>
    );
};

export { PresentationStateChip };
