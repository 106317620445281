import styles from './styles.module.css';

export const DrawerTitle = ({ children }: any) => {
    if (!children) return null;
    return (
        <h1 data-testid={'drawer-title'} className={styles['drawertitle']}>
            {children}
        </h1>
    );
};
