import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface TitleContextProps {
    title: string;
    setTitle: (title: string) => void;
}

const TitleContext = createContext<TitleContextProps | undefined>(undefined);

export const TitleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [title, setTitle] = useState<string>('');

    return (
        <TitleContext.Provider value={{ title, setTitle }}>
            {children}
        </TitleContext.Provider>
    );
};

export const useTitle = (): TitleContextProps => {
    const context = useContext(TitleContext);
    if (!context) {
        throw new Error('useTitle Error');
    }
    return context;
};
