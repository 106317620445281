import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';

export const styles: { [key: string]: SxProps<Theme> } = {
    container: {
        mt: 9,
        ml:4,
        mb:1,
        p: 5,
        background: '#fff',
        borderRadius: '10px',
        width:'100%'
    },
    filterRow: {
        display: 'flex',
        gap: 2,
        mb: 2,
    },
    filterItem: { 
        width: '50%',
    },
    buttonRow: {
        mt: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 2,
    },
};
