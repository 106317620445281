import React, { useState } from 'react';
import { Alert, AlertProps, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AlertComponentProps extends AlertProps {
    message: string;
    hideClose?: boolean;
    onClosePress?: () => void;
}

const AlertComponent: React.FC<AlertComponentProps> = ({
    message,
    severity,
    onClosePress,
    hideClose = false,
    ...props
}) => {
    const [open, setOpen] = useState(true);

    if (!open) return null;

    const renderMessageWithBold = (msg: string) => {
        const parts = msg.split(/<span>|<\/span>/g);
        return parts.map((part, index) =>
            index % 2 === 1 ? <strong key={index}>{part}</strong> : part
        );
    };

    const handleClose = () => {
        setOpen(false);
        if (onClosePress && typeof onClosePress === 'function') {
            onClosePress();
        }
    };

    return (
        <Alert
            severity={severity}
            {...props}
            action={!hideClose && 
                <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            style={{
                width: '100%',
                borderLeft: `4px solid ${getBorderColor(severity)}`,
                marginBottom: '16px'
            }}
        >
            {renderMessageWithBold(message)}
        </Alert>
    );
};

const getBorderColor = (severity: AlertProps['severity']) => {
    switch (severity) {
        case 'error':
            return '#f44336';
        case 'warning':
            return '#ffa726';
        case 'info':
            return '#2196f3';
        case 'success':
            return '#4caf50';
        default:
            return '#2196f3';
    }
};

export default AlertComponent;
