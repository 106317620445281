import React, { useEffect } from 'react';
import { useTitle } from '../contexts/TitleContext';

interface TitleSetterProps {
    title: string;
}

const TitleSetter: React.FC<TitleSetterProps> = ({ title }) => {
    const { setTitle } = useTitle();

    useEffect(() => {
        setTitle(title);
    }, [title, setTitle]);

    return null;
};

export default TitleSetter;
