import { Button } from '@mui/material';
import { FC, ElementType } from 'react';

interface TableActionButtonProps {
    onClick: any;
    IconComponent: ElementType;
    displayOption?: boolean;
    iconColor?: string,
    disabled?: boolean;
}

const TableActionButton: FC<TableActionButtonProps> = ({
    onClick,
    IconComponent,
    disabled = false,
    displayOption = true,
    iconColor
}) => {
    if (!displayOption) return null;
    return (
        <Button
            variant="outlined"
            disabled={disabled}
            sx={{
                minWidth: 35,
                minHeight: 35,
                padding: 0,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#384A52',
                borderColor: '#D9D9D9',
                '&:hover': {
                    backgroundColor: 'white'
                }
            }}
            onClick={onClick}
        >
            <IconComponent sx={{ color: iconColor }} />
        </Button>
    );
};

export default TableActionButton;
