import i18n from 'i18next';
import layoputEn from '../translations/en/layoutT.json';
import layoputEs from '../translations/es/layoutT.json';
import userEn from '../translations/en/users.json';
import userEs from '../translations/es/users.json';
import userFormEn from '../translations/en/userForm.json';
import userFormEs from '../translations/es/userForm.json';
import establishmentsEs from '../translations/es/establishments.json';
import establishmentsEn from '../translations/en/establishments.json';
import userCommunicationEs from '../translations/es/communications.json';
import userCommunicationEn from '../translations/en/communications.json';
import contentEn from '../translations/en/content.json';
import contentEs from '../translations/es/content.json';
import roleEs from '../translations/es/role.json';
import roleEn from '../translations/en/role.json';
import presentacionesEs from '../translations/es/presentaciones.json';
import presentacionesEn from '../translations/en/presentaciones.json';
import presentacionesRarEs from '../translations/es/presentacionesRar.json';
import presentacionesRarEn from '../translations/en/presentacionesRar.json';
import organismsEn from '../translations/en/organisms.json';
import organismsEs from '../translations/es/organisms.json';
import validationsEn from '../translations/en/validations.json';
import validationsEs from '../translations/es/validations.json';
import employeesEn from '../translations/en/employees.json';
import employeesEs from '../translations/es/employees.json';
import helpCenterEn from '../translations/en/helpCenter.json';
import helpCenterEs from '../translations/es/helpCenter.json';
import workPositionsEn from '../translations/en/workPositions.json';
import workPositionsEs from '../translations/es/workPositions.json';
import commonEn from '../translations/en/common.json';
import commonEs from '../translations/es/common.json';
i18n.init({
    debug: false,
    interpolation: { escapeValue: false },
    lng: 'es',
    resources: {
        es: {
            layoutT: layoputEs,
            users: userEs,
            userForm: userFormEs,
            communications: userCommunicationEs,
            content: contentEs,
            roles: roleEs,
            establishments: establishmentsEs,
            presentacionesRar: presentacionesRarEs,
            presentaciones: presentacionesEs,
            organisms: organismsEs,
            validations: validationsEs,
            employees: employeesEs,
            helpCenter: helpCenterEs,
            workPositions: workPositionsEs,
            common: commonEs
        },
        en: {
            layoutT: layoputEn,
            users: userEn,
            userForm: userFormEn,
            communications: userCommunicationEn,
            content: contentEn,
            roles: roleEn,
            establishments: establishmentsEn,
            presentacionesRar: presentacionesRarEn,
            presentaciones: presentacionesEn,
            organisms: organismsEn,
            validations: validationsEn,
            employees: employeesEn,
            helpCenter: helpCenterEn,
            workPositions: workPositionsEn,
            common: commonEn
        }
    }
});

export default i18n;
