import { getAccessValues } from "../components/layout/RoutesManager/access";
import useUserAccess from "./useUserAccess";

const useUserFeatures = () => {
    const access = useUserAccess();
    const FEATURES = getAccessValues(access);

    return {FEATURES}
}

export default useUserFeatures;