import { Cargo, CrearPresentacionDTO, responsableDTO, ResponsableFields } from "../types/types";
import { TFunction } from 'i18next';

/**
* Método que se encarga de construir el formulario para enviar la nómina de responsables rar
* @param presentacionDTO DTO con los datos de la presentación rar (id_establecimiento, responsables y archivo rar)
* @returns retorna un FormData con los datos de la presentación rar para enviar al backend
*/
export const buildFormData = (presentacionDTO: CrearPresentacionDTO, cargos: Cargo[]) => {
    const formData = new FormData();
    formData.append('archivo_rar', presentacionDTO.archivo_rar);
    formData.append('id_establecimiento', presentacionDTO.id_establecimiento.toString());
  
    const responsables: responsableDTO[] = presentacionDTO.responsables.map((responsable, index) => {
      const camposObligatorios: Array<keyof responsableDTO> = [
        'nombre',
        'apellido',
        'cuit_cuil_cuip',
        'cargo',
      ];
  
      const camposOpcionalesR: Array<keyof responsableDTO> = [
        'representacion',
        'tipo_contratacion',
        'titulo_habilitante',
        'nro_matricula',
        'entidad_otorgante',
      ];
  
      camposObligatorios.forEach(field => {
        const value = responsable[field];
        if (value === undefined || value === null) {
          throw new Error(`El campo '${field}' es obligatorio para el responsable ${index + 1}.`);
        }
      });
  
      const isResponsible = cargos.some(cargo => cargo.codigo === 'R' && cargo.id === responsable.cargo);
  
      if (isResponsible) {
        camposOpcionalesR.forEach(field => {
          const value = responsable[field];
          if (value !== undefined && value !== null && !!value) {
            (responsable as any)[field] = value === 0 ? null : value.toString();
          }
        });
      } else {
        camposOpcionalesR.forEach(field => {
          const value = responsable[field];
          if (value === undefined || value === null) {
            throw new Error(`El campo '${field}' es obligatorio cuando el cargo no es 'R' para el responsable ${index + 1}.`);
          }
          if(field === 'representacion' || field === 'tipo_contratacion'){
            (responsable as any)[field] =  Number(value);
          }else {
            (responsable as any)[field] = value.toString();
          }
        });
      }
  
      return responsable;
    });
    formData.append('responsables', JSON.stringify(responsables));
  
    return formData;
  };
  
/**
 * Método para preparar los datos de la tabla de responsables rar para exportar a excel
 * @param data  Detalle de nomina de presentaciones rar
 * @param customHeaders  Cabeceras personalizadas para la exportación
 * @returns  Retorna los datos de la tabla de responsables rar listos para exportar a excel
 */
export const prepareExcelData = (data: any[], customHeaders: any[]) => {
    const worksheetData: any[] = [];

    data.forEach(item => {
        const dataRow: any[] = [];
        customHeaders.forEach(header => {
            const field = header.field;
            if (field === 'nombreApellido') {
                const [nombre, apellido] = (item[field] || '').split(' ');
                dataRow.push(nombre || ''); 
                dataRow.push(apellido || ''); 
            } else if (field === 'fechaIngreso' || field === 'fechaInicioExposicion') {
                dataRow.push((item[field]) || '');
            }else {
                dataRow.push(item[field] || '');
            }
        });
        worksheetData.push(dataRow);
    });

    return worksheetData;
};

export const addFieldToFormData = (key: string, value: string | number | Blob | undefined, formData: FormData) => {
    if (value !== undefined) {
        if (typeof value === 'number') {
            value = value.toString();  
        }
        formData.append(key, value);
    }
    return formData;
};

export const translate = (key: string, t: TFunction): string => t(key) as string;
