import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styles from '../styles.module.css';
import useLogin, { HELPERTEXT } from '../hook/useLogin';
import AlertComponent from '../../../components/AlertMessage/AlertMessage';
import { useEffect, useState } from 'react';

const UserConfirmation = () => {
    const { t } = useTranslation('userForm');
    const navigate = useNavigate();

    const {
        usuario,
        setUsuario,
        verificationCode,
        handleShowPassword,
        setVerificationCode,
        showPassword,
        newPassword,
        setNewPassword,
        error,
        handleConfirmUser,
        loading
    } = useLogin();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    useEffect(() => {
        setIsButtonDisabled(
            !usuario || !verificationCode || !newPassword.password1 || !newPassword.password2
        );
    }, [usuario, verificationCode, newPassword]);

    return (
        <>
            <Typography variant="subtitle1" fontWeight="bold">
                {t('activateUserTitle')}
            </Typography>
            <TextField
                required
                name="user"
                className={styles.formElement}
                label={t('user')}
                value={usuario || ''}
                onChange={(e) => setUsuario(e.target.value)}
                variant="outlined"
                error={!!error}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
            />
            <TextField
                required
                name="verificationCode"
                className={styles.formElement}
                label={t('verificationCode')}
                type="text"
                value={verificationCode || ''}
                onChange={(e) => setVerificationCode(e.target.value)}
                variant="outlined"
                error={!!error}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
            />
            <TextField
                required
                name="password"
                className={styles.formElement}
                id="filled-required"
                label={t('password')}
                type={showPassword.password ? 'text' : 'password'}
                value={newPassword?.password1 || ''}
                onChange={(e) => setNewPassword({ ...newPassword, password1: e.target.value })}
                variant="outlined"
                error={!!error}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleShowPassword('password')}
                                edge="end"
                            >
                                {showPassword.password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                helperText={!error && HELPERTEXT}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
            />
            <TextField
                required
                name="passwordConfirm"
                className={styles.formElement}
                label={t('passwordConfirm')}
                type={showPassword.passwordConfirm ? 'text' : 'password'}
                value={newPassword?.password2 || ''}
                onChange={(e) => setNewPassword({ ...newPassword, password2: e.target.value })}
                variant="outlined"
                error={!!error}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleShowPassword('passwordConfirm')}
                                edge="end"
                            >
                                {showPassword.passwordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
            />
            <Button
                className={styles.btnSubmit}
                variant="contained"
                onClick={handleConfirmUser}
                disabled={isButtonDisabled}
                sx={{
                    backgroundColor: '#5A911F',
                    '&:hover': {
                        backgroundColor: '#3D6113' // Darker shade for hover state
                    }
                }}
            >
                {t('activateUser')}
                {loading && (
                    <CircularProgress style={{ margin: '0 10px' }} color="inherit" size="1.5em" />
                )}
            </Button>
            <Button
                className={styles.btnSubmit}
                variant="outlined"
                onClick={() => navigate('/login')}
                sx={{
                    borderColor: '#5A911F',
                    color: '#5A911F',
                    '&:hover': {
                        borderColor: '#3D6113',
                        backgroundColor: `rgba(90, 145, 31,0.08)`
                    }
                }}
            >
                {t('goBack')}
            </Button>
            {error && (
                <AlertComponent message={error} severity="error" style={{ marginTop: '16px' }} />
            )}
        </>
    );
};

export default UserConfirmation;
