import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styles from '../styles.module.css';
import useLogin from '../hook/useLogin';
import { useEffect, useState } from 'react';
import { AlertDialog } from '../../../components/Alert/AlertDialog';
import AlertComponent from '../../../components/AlertMessage/AlertMessage';

interface LoginFormProps {
    title: string;
    subTitle: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ title, subTitle }) => {
    const { t } = useTranslation('userForm');
    const navigate = useNavigate();

    const {
        usuario,
        setUsuario,
        password,
        setPassword,
        showPassword,
        handleShowPassword,
        error,
        errorType,
        setError,
        setErrorType,
        handleSubmit,
        loading
    } = useLogin();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    useEffect(() => {
        setIsButtonDisabled(!usuario || !password);
    }, [usuario, password]);

    const handleAlertClosePress = () => {
        setError('');
        setErrorType(null);
    };

    return (
        <>
            <Typography variant="subtitle1" fontWeight="bold">
                {title}
            </Typography>
            <Typography variant="subtitle2">{subTitle}</Typography>
            <TextField
                required
                name="user"
                className={styles.formElement}
                id="filled-required"
                label={t('user')}
                value={usuario || ''}
                onChange={(e) => setUsuario(e.target.value)}
                variant="outlined"
                error={!!error}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
            />
            <TextField
                required
                name="password"
                className={styles.formElement}
                id="filled-password-input"
                label={t('password')}
                type={showPassword.password ? 'text' : 'password'}
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                error={!!error}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: '#5A911F' // Border color on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5A911F' // Border color when focused
                        }
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: '#5A911F' // Label color when focused
                        }
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleShowPassword('password')}
                                edge="end"
                            >
                                {showPassword.password ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {error && errorType === 'alert-component' && (
                <AlertComponent
                    message={error}
                    severity="error"
                    onClosePress={handleAlertClosePress}
                    style={{ marginTop: '16px', width: 30 }}
                />
            )}
            {error && errorType === 'alert-dialog' && (
                <AlertDialog
                    title="El usuario se encuentra bloqueado o inactivo"
                    message={
                        'Por favor, ponte en contacto con un administrador o activá tu cuenta si aún no lo has hecho'
                    }
                    icon="cancel"
                    closeAction={() => {
                        setError('');
                        setErrorType(null);
                    }}
                    confirmAction={() => {
                        setError('');
                        setErrorType(null);
                    }}
                    // style={{ marginTop: '16px', width: 30 }}
                />
            )}
            <div className={styles.loginFormButtonsContainer}>
                <div className={styles.btnTextContainer}>
                    <Button
                        className={styles.btnText}
                        variant="text"
                        onClick={() => navigate('recuperar-clave')}
                    >
                        {t('forgotPassword')}
                    </Button>
                </div>
                <Button
                    className={styles.btnSubmit}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                    sx={{
                        backgroundColor: '#5A911F',
                        '&:hover': {
                            backgroundColor: '#3D6113' // Darker shade for hover state
                        }
                    }}
                >
                    {loading ? 'Ingresando...' : `${t('loginUser')}`}
                    {loading && (
                        <CircularProgress
                            style={{ margin: '0 10px' }}
                            color="inherit"
                            size="1.5em"
                        />
                    )}
                </Button>
                <div className={styles.btnTextContainer}>
                    <Button
                        className={styles.btnText}
                        variant="text"
                        onClick={() => navigate('confirmar-usuario')}
                    >
                        {t('confirmUser')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
