import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import Input from '../../../components/Input/Input';
import AutocompleteComponent from '../../../components/layout/Autocomplete';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/layout/DatePicker';
import { PRESENTATION_STATE, PRESENTATION_TYPE } from '../consts';
import ClearIcon from '@mui/icons-material/Clear';

interface FiltersProps {
  extraFilters: any;
  handleChangeExtraFilters: (e: any) => void;
  isShowMoreFiltersOpen: boolean;
}

const FiltersComponent: React.FC<FiltersProps> = ({ extraFilters, handleChangeExtraFilters, isShowMoreFiltersOpen  }) => {
  const { t } = useTranslation('presentaciones');

  const presentationTypeOptions = Object.entries(PRESENTATION_TYPE)
  .filter(([key, value]) => typeof value === 'number') 
  .map(([key, value]) => ({
    label: t(`types.${key.toLowerCase()}`), 
    value, 
  }));

const presentationStateOptions = Object.entries(PRESENTATION_STATE)
  .filter(([key, value]) => typeof value === 'number') 
  .map(([key, value]) => ({
    label: key.replace(/_/g, ' '),
    value,
  }));

  const clearDate = (field: string) => {
    handleChangeExtraFilters({ target: { name: field, value: null } });
  };

  return (
    <Grid container spacing={2}>

      <Grid item xs={12} sm={6} md={6}>
        <Input
          name="contrato"
          label={t('filters.contract') as string}
          value={extraFilters?.contrato || ''}
          onChange={handleChangeExtraFilters}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
      <Input
              name="establecimiento"
              label={t('filters.establishment') as string}
              value={extraFilters?.establecimiento || ''}
              onChange={handleChangeExtraFilters}
            />

       
      </Grid>

      {isShowMoreFiltersOpen && (
        <>

        <Grid item xs={12} sm={6} md={3}>
          <AutocompleteComponent
            name="idTipo"
            label={t('filters.presentationType')}
            value={presentationTypeOptions.find(option => option.value === extraFilters?.idTipo) || null}
            options={presentationTypeOptions}
            templateLabel={(option: any) => option?.label || ''}
            onChange={(value: any) =>
              handleChangeExtraFilters({ target: { name: 'idTipo', value: value?.value } })
            }
          />
        </Grid>


        <Grid item xs={12} sm={6} md={3}>
        <AutocompleteComponent
          name="estado"
          label={t('filters.state')}
          value={presentationStateOptions.find(option => option.value === extraFilters?.estado) || null}
          options={presentationStateOptions}
          templateLabel={(option: any) => option?.label || ''}
          onChange={(value: any) =>
            handleChangeExtraFilters({ target: { name: 'estado', value: value?.value } })
          }
        />
          </Grid>

          <Grid item xs={12} sm={6} md={2.9} style={{ position: 'relative' }}>
            <DatePicker
              name="fechaDesde"
              label={t('filters.startDate')}
              value={extraFilters?.fechaDesde || null}
              onChange={(date: Date | null) =>
                handleChangeExtraFilters({ target: { name: 'fechaDesde', value: date } })
              }             
            />
            {extraFilters?.fechaDesde && (
              <IconButton
                onClick={() => clearDate('fechaDesde')}
                style={{ position: 'absolute', top: 28, right: 40 }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={0.2} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h3" sx={{ fontWeight: 'bold', mt: 2, color:'#707070' }}>
              -
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2.9} style={{ position: 'relative' }}>
            <DatePicker
              name="fechaHasta"
              label={t('filters.endDate')}
              value={extraFilters?.fechaHasta || null}
              onChange={(date: Date | null) =>
                handleChangeExtraFilters({ target: { name: 'fechaHasta', value: date } })
              }
            />
            {extraFilters?.fechaHasta && (
              <IconButton
                onClick={() => clearDate('fechaHasta')}
                style={{ position: 'absolute', top: 28, right: 40 }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FiltersComponent;

