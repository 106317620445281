import { Avatar, Card, CardHeader, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout'; // Importa el icono de logout
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../features/slices/applicationSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useLogoutMutation } from '../../features/auth/authApi';
import { handleErrors } from '../../utils/common';
import { logout } from '../../features/auth/authSlice';

function UserCardHeader({ isMobile, handleOpenNavMenu, user, role }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [userLogout, { isLoading: loginOut }] = useLogoutMutation();
    
    const getInitials = () => {
        const values = user?.nombre?.split(' ');
        let initials: string = '';
        values?.forEach((i: any) => {
            if (i) {
                initials += i.charAt(0).toUpperCase();
            }
        });
        return initials;
    };
    const logoutHandler = () => {
        dispatch(
            showAlert({
                title: '¿Seguro que desea cerrar sesión?',
                cancelText: 'Cancelar',
                icon: 'info',
                confirmAction: handleLogOut
            })
        );
    };

    const handleLogOut = async () => {
        const data = {
            username: user?.usuario
        };
        const response: any = await userLogout(data);
        if (response?.error) {
            handleErrors(response?.error);
        } else {
            dispatch(logout());
            navigate('/login');
        }
    };

    return (
        <Card
            sx={{
                display: 'flex',
                maxWidth: 345,
                '& .MuiCardHeader-root': {
                    padding: '8px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                    float: 'right'
                },
                '& .MuiCardHeader-subheader': {
                    textTransform: 'capitalize',
                    maxWidth: '200px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '10px',
                    float: 'right'
                },
                '& .MuiCardHeader-avatar': {
                    marginRight: isMobile ? 0 : '16px'
                }
            }}
        >
            <CardHeader
                title={isMobile ? null : `${user?.nombre || ""} ${user?.apellido || ""}`}
                subheader={isMobile ? null : role + `${user?.roles[0].nombre}`}
            />

            <CardHeader
                avatar={
                    <Avatar
                        sx={{
                            bgcolor: 'var(--primary)',
                            fontSize: getInitials()?.length > 2 ? '17px' : '20px'
                        }}
                        aria-label="recipe"
                        onClick={isMobile ? handleOpenNavMenu : undefined}
                    >
                        {getInitials()?.substring(0, 3)}
                    </Avatar>
                }
                onClick={() => {
                    navigate('usuarios/perfil');
                }}
            />

            <CardHeader
                action={
                    <IconButton
                        onClick={logoutHandler}
                    >
                        <LogoutIcon />
                    </IconButton>
                }
            />
        </Card>
    );
}

export default UserCardHeader;
