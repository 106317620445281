// Colores principales de cada Theme
const blue = '#1473E6';
const green = '#5A911F';

export const createPalette = (primaryColor: string) => {
    const isGreen = primaryColor === green;

    return {
        primary: {
            main: primaryColor,
            light: isGreen ? '#8BB94C' : '#4791db', 
            dark: isGreen ? '#3D6113' : '#115293',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f50057',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ffa726',
        },
        info: {
            main: '#2196f3',
        },
        success: {
            main: '#4caf50',
        },
        background: {
            default: '#F3F8F8',
            paper: '#ffffff',
        },
        text: {
            primary: '#1E272E',
            secondary: '#757575',
        },
    };
};
