import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Paper, Stack, Typography } from '@mui/material';
import { selectCurrentUser } from '../../features/auth/authSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useIsMobile } from '../../hooks/useIsMobile';
import Logo from '../../assets/image/logo.png';
import ImageLogin from './components/ImageLogin';
import LoginForm from './components/LoginForm';
import PasswordRecovery from './components/PasswordRecovery';
import styles from './styles.module.css';
import UserConfirmation from './components/UserConfirmation';
import { useDispatch } from 'react-redux';
import { setTheme } from '../../redux/slices/themeSlice';

export interface ViewsProp {
    [key: string]: JSX.Element;
}

interface LoginProps {
    theme?: 'green' | 'blue';
}

const Login: React.FC<LoginProps> = ({ theme }) => {
    const user = useAppSelector(selectCurrentUser);
    const { isMobile, isTablet } = useIsMobile();
    const dispatch = useDispatch();
    const location = useLocation();
    const [logo, setLogo] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [backgroundClass, setBackgroundClass] = useState(styles.root);
    const { id } = useParams();

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme');
        let currentTheme = theme || storedTheme;
        currentTheme = 'green';

        // if (location.pathname.includes('login-simp')) {
        //     currentTheme = 'green';
        // } else if (location.pathname.includes('login-backoffice')) {
        //     currentTheme = 'blue';
        // }

        if (true) {
            localStorage.setItem('theme', 'green');
            dispatch(setTheme('green'));
            setLogo('/logo-green.png');
            setTitle('SIMP');
            setSubTitle('Sistema Integral de Medicina Preventiva');
            setBackgroundClass(`${styles.overlay} ${styles.overlayGreen}`);
        }
        // else if (theme === 'blue' || currentTheme === 'blue') {
        //     localStorage.setItem('theme', 'blue');
        //     dispatch(setTheme('blue'));
        //     setLogo('/logo-blue.png');
        //     setTitle('Backoffice');
        //     setBackgroundClass(`${styles.overlay} ${styles.overlayBlue}`);
        //     document.title = 'Backoffice';
        // }
    }, [theme, location.pathname, dispatch]);

    if (user) {
        return <Navigate to="/simp" replace />;
    }

    const LoginViewManagement = (props: any) => {
        const views: ViewsProp = {
            login: <LoginForm {...props} title={title} subTitle={subTitle} />,
            'confirmar-usuario': <UserConfirmation {...props} />,
            'recuperar-clave': <PasswordRecovery {...props} />
        };
        if (id && !views[id]) return <Navigate to="/login" replace />;
        return id ? views[id] : views['login'];
    };

    return (
        <Stack className={styles.root}>
            <div className={backgroundClass}></div>
            <Paper className={styles.paperContainer} elevation={1}>
                <Stack spacing={4} className={styles.loginFormContainer}>
                    <img src={logo} alt="banner-login" className={styles.logoPrincipal} />

                    <LoginViewManagement />
                </Stack>
            </Paper>
        </Stack>
    );
};

export default Login;
