import { useEffect, useState } from "react";
import { downloadBase64File } from "../../../utils/common";
import { useLazyGetPresentacionRarPDFbyIdQuery } from "../../../services/presentacionesRarApi";
import { useLazyGetPresentacionRGRLPDFbyIdQuery } from "../../../services/presentacionesApi";

export enum PdfType {
    RAR = 'RAR',
    RGRL = 'RGRL'
}

const useReports = (idPresentacion?: number, establecimientoNombre?: string, codigoSRT?: string) => {
    const [triggerGetPresentacionRarPDFById, { data: pdfRarData, isLoading: isLoadingRar, error: rarError }] = useLazyGetPresentacionRarPDFbyIdQuery();
    const [triggerGetPresentacionRGRLPDFById, { data: pdfRgrlData, isLoading: isLoadingRgrl, error: rgrlError }] = useLazyGetPresentacionRGRLPDFbyIdQuery();
    
    const [processPdf, setProcessPdf] = useState(false);
    const [namePdf, setNamePdf] = useState('');
    const [currentPdfType, setCurrentPdfType] = useState<PdfType | null>(null);

    const handleDownloadPdf = (idTipo?: number) => {
        // setProcessPdf(true);
        // const pdfType = idTipo !== 4 ? PdfType.RGRL : PdfType.RAR;

        // setCurrentPdfType(pdfType);

        // const reportName = `${establecimientoNombre || 'Establecimiento'} - Cod SRT_${codigoSRT || 'CodigoSRT'} -  ${pdfType}`; //agregar index de iteracion al nombre
        // setNamePdf(reportName);

        // if (pdfType === PdfType.RGRL) {
        //     triggerGetPresentacionRGRLPDFById({ idPresentacion: idPresentacion! });
        // } else if (pdfType === PdfType.RAR) {
        //     triggerGetPresentacionRarPDFById({ idPresentacion: idPresentacion! });
        // }
    }

    const downloadPdf = (pdfData: string, currentNameIndex?: number) => {
        downloadBase64File(pdfData, idPresentacion!, namePdf, currentNameIndex);
        setProcessPdf(false);
    };

    useEffect(() => {
        if (currentPdfType === PdfType.RAR && pdfRarData) {
            pdfRarData.data.presignedUrls.forEach((url, index) => {
                downloadPdf(url, index);
            });
        } else if (currentPdfType === PdfType.RGRL && pdfRgrlData) {
            pdfRgrlData.data.presignedUrls.forEach((url, index) => {
                downloadPdf(url, index);
            });
        }
    }, [pdfRarData, pdfRgrlData, currentPdfType, idPresentacion, namePdf]);

    return {
        handleDownloadPdf,
        isLoading: isLoadingRar || isLoadingRgrl,
        error: rarError || rgrlError,
    };
}

export default useReports;
