import { useState, useEffect } from 'react';
import { useAssignEstablecimientoToEmpleadosMutation, useGetNominaQuery } from '../../../../services/nominaApi';
import { showAlert } from '../../../../features/slices/applicationSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface Filters {
    nombre: string;
    apellido: string;
    cuil: string;
}

interface Empleado {
    id: number;
    nombre: string;
    apellido: string | null;
    cuil: string;
    organismo: {
        id: number;
        nombre: string;
        cuit: string;
    };
}

const useNomina = (contractNumber: string, idEstablecimiento:string,  rowsPerPage: number, page: number,organismsId: string) => {
    const { t } = useTranslation('employees');
    const [selectedEmpleados, setSelectedEmpleados] = useState<Empleado[]>([]);
    const [filters, setFilters] = useState<Filters>({ nombre: '', apellido: '', cuil: '' });

    const { data: initialSelectedEmpleadosData } = useGetNominaQuery({
        idEstablecimiento,
        skip: 0,
        take: 1000
    });

    const { data, error, isLoading } = useGetNominaQuery({
        contrato: contractNumber,
        ...(filters.nombre && { nombre: filters.nombre }),
        ...(filters.apellido && { apellido: filters.apellido }),
        ...(filters.cuil && { cuil: filters.cuil }),
        skip: page * rowsPerPage,
        take: rowsPerPage
    });

    const dispatch = useDispatch(); 
    const [assignEstablecimientoToEmpleados] = useAssignEstablecimientoToEmpleadosMutation(); 

    const totalRecords = data?.data?.count || 0;

    useEffect(() => {
        if (initialSelectedEmpleadosData?.data?.data) {
            setSelectedEmpleados(initialSelectedEmpleadosData.data.data);
        }
    }, [initialSelectedEmpleadosData]);
    
    const handleSelectEmpleado = (empleado: Empleado) => {
        setSelectedEmpleados((prevSelected) => {
            if (prevSelected.some((e) => e.id === empleado.id)) {
                return prevSelected.filter((e) => e.id !== empleado.id);
            } else {
                return [...prevSelected, empleado];
            }
        });
    };

    const handleGuardar = async (idEstablecimiento: number) => {
        const selectedIds = selectedEmpleados.map((empleado) => empleado.id);

        if (selectedIds.length > 0) {
            try {
                await assignEstablecimientoToEmpleados({
                    idEstablecimiento: idEstablecimiento,
                    idsEmpleados: selectedIds,
                }).unwrap();

                dispatch(showAlert({
                    title: t('alerts.AssignmentNomina.success.title'),
                    message: t('alerts.AssignmentNomina.success.message'),
                    icon: 'success',
                    confirmText: t('alerts.AssignmentNomina.success.confirmText'),
                }));
            } catch (error) {
                dispatch(showAlert({
                    title: t('alerts.AssignmentNomina.error.title'),
                    message: t('alerts.AssignmentNomina.error.message'),
                    icon: 'error',
                    confirmText: t('alerts.AssignmentNomina.error.confirmText'),
                }));
            }
        } else {
            dispatch(showAlert({
                title: t('alerts.AssignmentNomina.no_selection.title'),
                message: t('alerts.AssignmentNomina.no_selection.message'),
                icon: 'error',
                confirmText: t('alerts.AssignmentNomina.no_selection.confirmText'),
            }));
        }
    };
    const handleClearFilters = () => {
        setFilters({ nombre: '', apellido: '', cuil: '' });
    };

    const handleBuscar = (nombre: string, apellido: string, cuil: string) => {
        setFilters({ nombre, apellido, cuil });
    };

    const handleClearSelection = () => {
        setSelectedEmpleados([]);
    };

    return {
        data,
        totalRecords,
        selectedEmpleados,
        filters,
        isLoading,
        error,
        handleSelectEmpleado,
        handleGuardar,
        handleClearFilters,
        handleBuscar,
        handleClearSelection
    };
};

export default useNomina;