import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Responsable, ResponsableState } from '../../pages/Rar/types/types';

export interface GremioContratistaState {
    gremio: any[];
    contratista: any[]; // El responsable actual con 'responsable' true
    errorGremio: string | null;
    errorContratista: string | null;
}
const initialState: GremioContratistaState = {
  gremio: [],
  contratista: [],
  errorGremio: null,
  errorContratista: null,
  
};

const gremioContratistaSlice = createSlice({
  name: 'gremioContratista',
  initialState,
  reducers: {
    addGremio: (state, action: PayloadAction<any>) => {
      state.gremio.push(action.payload);
    },
    updateGremio: (state, action: PayloadAction<any>) => {
      const index = state.gremio.findIndex((r: any)  => r.id === action.payload.id);
      if (index !== -1) {
        state.gremio[index] = action.payload;
      }
    },
    removeGremio: (state, action: PayloadAction<number>) => {
      try {
        state.gremio = state.gremio.filter((r: any) => r.id !== action.payload);
      } catch (error) {
        state.errorGremio = 'Error';  
      }
    },
    addContratista: (state, action: PayloadAction<any>) => {
        state.contratista.push(action.payload);
      },
    updateContratista: (state, action: PayloadAction<any>) => {
        const index = state.contratista.findIndex((r: any)  => r.id === action.payload.id);
        if (index !== -1) {
          state.contratista[index] = action.payload;
        }
      },
    removeContratista: (state, action: PayloadAction<number>) => {
        try {
          state.contratista = state.contratista.filter((r: any) => r.id !== action.payload);
        } catch (error) {
          state.errorContratista = 'Error';  
        }
      },
    clearFields: (state) => {

      state.gremio = [];
      state.contratista = [];
    },
    clearError: (state) => {
      state.errorGremio = null; 
      state.errorContratista = null;
    },
  },
});

export const { addGremio, addContratista, updateGremio, updateContratista, removeGremio, removeContratista, clearFields, clearError } = gremioContratistaSlice.actions;
export default gremioContratistaSlice.reducer;
