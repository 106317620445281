import _ from 'lodash';
import { useLocation, useNavigate  } from 'react-router-dom';
import { useLazyGetDetalleNominaRarQuery, usePresentarRarMutation  } from '../../../services/presentacionesRarApi';
import useExportToExcel from '../../../utils/hooks/useExportToExcel';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { clearPresentacionItemSelected } from '../../../redux/slices/rarPresentacionSlice';
import { useCallback, useEffect, useState } from 'react';
import { prepareExcelData } from '../utilsRar/utils';
import { formatFechaISOToLocal } from '../../../utils/common';
import { useLazyGetNominaDownloadUrlQuery } from '../../../services/presentacionesApi';
import { enqueueSnackbar } from 'notistack';



interface ModalState {
  title: any;
  subtitle: any;
}

const useNominaRar = (filters: any, idRar?: number) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoadingDescarga, setIsLoadingDescarga] = useState(false);
  const [errorDescarga, setErrorDescarga] = useState<ModalState | null>(null);
  const [ openModal, setOpenModal ] = useState(false);
  const [ modalState, setModalState ] = useState<ModalState | null>(null);
  const [getNominaUrl, {data: nominaUrl, error: nominaUrlError, isLoading: isLoadingNominaUrl}] = useLazyGetNominaDownloadUrlQuery()
  const { exportToExcel } = useExportToExcel();
  const [triggerGetDetalleNominaRar, { data: presentacionDetalle, error: errorDetalle, isFetching: isLoadingDetalle }] = useLazyGetDetalleNominaRarQuery();
  const [presentarRar, { isLoading: isLoadingCambioEstado, isSuccess: isSuccessCambioEstado, error: errorCambioEstado }] = usePresentarRarMutation();
  const [ successPresentacionState , setSuccessPresentacionState ] = useState<ModalState | null>(null);
  const [isProcessingXLSXFile, setIsProcessingXLSXFile] = useState<boolean>(false)

  /**
   * Fetch para buscar detalle de nomina optimizado
   */
  const fetchDetalleNomina = useCallback(() => {
    triggerGetDetalleNominaRar({
      id_presentacion: idRar!,
      ...filters,
    });
  }, [idRar, triggerGetDetalleNominaRar, filters]);
  
  useEffect(() => {
    if (idRar) {
      fetchDetalleNomina();
    }
  }, [idRar, fetchDetalleNomina]);
;

  const handleClickVolver = () => {
      dispatch(clearPresentacionItemSelected());
      navigate(-1);
    };

    const downloadNominaExcel = async (idPresentacion: number, fecha: string | undefined, fileType: 1 | 2) => {
      try {
        setIsProcessingXLSXFile(true);
        const response = await getNominaUrl({ idPresentacion, idTipo: fileType });
        const presignedUrls: string[] = response?.data?.data?.presignedUrls || [];
        const fechaFormat = formatFechaISOToLocal(fecha);
    
        presignedUrls.forEach(async (presignedUrl, index) => {
          const fileResponse = await fetch(presignedUrl);
          const blob = await fileResponse.blob();
          const url = window.URL.createObjectURL(blob);
    
          const link = document.createElement('a');
          link.href = url;
          link.download = `Nomina_${fechaFormat}.xlsx`;
          document.body.appendChild(link);
          link.click();
    
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        });
      } catch (error) {
        enqueueSnackbar('No se pudo descargar el archivo', {
          variant: 'error'
        });
      } finally {
        setIsProcessingXLSXFile(false);
      }
    };

  const handleExportToExcel = async (data: any, fecha: string | undefined )=> {
    setIsLoadingDescarga(true);
    try {
        const customHeaders = [
          { title: 'CUIL', field: 'cuil' },
          { title: 'Nombre', field: 'nombre' },
          { title: 'Apellido', field: 'apellido' },
          { title: 'Fecha de Ingreso a la Empresa(DD/MM/AAAA)', field: 'fechaIngreso' },
          { title: 'Fecha de Inicio de Exposición(DD/MM/AAAA)', field: 'fechaInicioExposicion' },
          { title: 'Puesto de Trabajo', field: 'puesto' },
          { title: 'Sector de Trabajo', field: 'sector' },
          { title: 'Identificación de Riesgos según Código ESOP', field: 'riesgos' },
        ];
  
        const headers = customHeaders.map(header => header.title);
        const excelData = prepareExcelData(data || [], customHeaders);
        const fechaFormat = formatFechaISOToLocal(fecha);
        await exportToExcel(excelData, headers, `Nomina_${fechaFormat}`);
    } catch (error) {
          setErrorDescarga({
            title: "No se pudo completar la descarga",
            subtitle: 'Por favor, intente nuevamente',
          });
      } finally {
        setIsLoadingDescarga(false);
      }
    };

    const handlePresentarRar = () => {
      setOpenModal(true);
      setModalState({
        title: 'Enviar Presentación',
        subtitle: 'El que suscribe, como responsable, DECLARA BAJO JURAMENTO que los datos aquí consignados son correctos y completos, y que no se ha omitido ni falseado ningún dato',
      });

    }

    const handleCloseModal = () => {
      setOpenModal(false);
      setModalState(null);
    }

    const handleCambioEstadoModal = () => {
      setOpenModal(false);
      setSuccessPresentacionState(null);
      navigate(-1);
    }

    const handleEnviarPresentacionRarCambioEstado = async () => {
      handleCloseModal();
    
      try {
        await presentarRar({
          idPresentacion: idRar!,
        }).unwrap();
        handleSuccess();
      } catch (error) {
        handleError();
      }
    };
    
    const handleSuccess = () => {
        setSuccessPresentacionState({
          title: 'Presentación enviada correctamente',
          subtitle: 'La presentación fue enviada correctamente',
        });
    };

    const handleError = () => {
      setErrorDescarga({
        title: "No se pudo enviar la presentación",
        subtitle: 'Por favor, intente nuevamente',
      });
    };



    return {
      presentacionDetalle,
      errorDetalle,
      isLoadingDetalle,
      isLoadingDescarga,
      errorDescarga,
      openModal,
      modalState,
      isSuccessCambioEstado,
      isLoadingCambioEstado,
      successPresentacionState,
      nominaUrl,
      nominaUrlError,
      isProcessingXLSXFile,
      handleClickVolver,
      handleExportToExcel,
      setErrorDescarga, 
      handlePresentarRar,
      handleCloseModal,
      setOpenModal,
      setModalState,
      handleEnviarPresentacionRarCambioEstado,
      handleCambioEstadoModal,
      fetchDetalleNomina,
      downloadNominaExcel,
    };
  };
  
  export default useNominaRar;
