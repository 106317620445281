import React, { useCallback, useEffect, useState } from 'react';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import useNominaRar from '../../hooks/useNominaRar';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    MenuItem,
    styled,
    TextField,
    Typography
} from '@mui/material';
import BackdropComponent from '../../../../components/Backdrop/BackdropComponent';
import useGlobalFilters from '../../../../hooks/useGlobalFilters';
import { useTranslation } from 'react-i18next';
import { styles } from './Styles';
import { useTitle } from '../../../../contexts/TitleContext';
import usePresentations from '../../../Presentaciones/hooks/usePresentations';
import { useGetResponsablesByPresentacionIdQuery } from '../../../../services/presentacionesRarApi';
import useToggle from '../../../../components/Cabeceras/hooks/useToggle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export enum NOMINA_STATE {
    OK = 'OK',
    INFO = 'INFO',
    ERROR = 'ERROR'
}

interface Column {
    title: string;
    render: (row: any) => React.ReactNode;
    align?: 'left' | 'center' | 'right';
}

const RarView = () => {
    const presentacionItem = useAppSelector((state) => state.rarPresentacion.presentacionItem);
    const { setTitle } = useTitle();
    const selectedEstablishmentId = useAppSelector(
        (state) => state.establishment.selectedEstablishmentId
    );
    const { t } = useTranslation('presentacionesRar');
    const { filters, setFilters } = useGlobalFilters();
    const [cuilFilter, setCuilFilter] = useState<string>('');
    const [riesgosFilter, setRiesgosFilter] = useState<string>('');
    const [expuestoFilter, setExpuestoFilter] = useState<boolean | ''>('');
    const [isExpanded, toggleExpanded] = useToggle(true);

    const {
        presentacionDetalle,
        isLoadingDetalle,
        isLoadingDescarga,
        isLoadingCambioEstado,
        handleClickVolver,
        fetchDetalleNomina
    } = useNominaRar(filters, presentacionItem?.id);

    const { establishmentDetail } = usePresentations({
        filters: filters,
        establishmentId: selectedEstablishmentId
    });

    const {
        data: responsables,
        isLoading,
        error
    } = useGetResponsablesByPresentacionIdQuery(
        { idPresentacion: presentacionItem?.id ?? 0 },
        { skip: !presentacionItem }
    );

    useEffect(() => {
        if (presentacionItem) {
            fetchDetalleNomina();
        }
    }, [presentacionItem, fetchDetalleNomina]);

    useEffect(() => {
        if (presentacionItem) {
            localStorage.setItem('selectedPresentationId', presentacionItem?.id.toString());
        }
    }, [presentacionItem]);

    useEffect(() => {
        setTitle(t('headerRar.title', { establishmentName: establishmentDetail?.nombre ?? 'N/A' }));
    }, [setTitle]);

    const handleCuilChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCuilFilter(value);
    };
    const handleRiesgosChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRiesgosFilter(event.target.value as string);
    };

    const handleSearch = () => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            cuil: cuilFilter,
            expuesto: expuestoFilter
        }));
    };

    const handleClearFilter = () => {
        setCuilFilter('');
        setExpuestoFilter('');
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            cuil: undefined,
            expuesto: undefined
        }));
    };

    const ExpandableItemWrapper = styled('div')(({ theme }) => ({
        marginLeft: '25px'
    }));

    const TitleWrapper = styled('div')({
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    });

    const iconButtonStyle = {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        marginLeft: '15px',
        alignSelf: 'center'
    };

    const columns: Column[] = [
        { title: t('tableNominaDetail.columns.cuil'), render: (row) => row.cuil },
        {
            title: t('tableNominaDetail.columns.fullName'),
            render: (row) => `${row.nombre} ${row.apellido}`
        },
        { title: t('tableNominaDetail.columns.ingressDate'), render: (row) => row.fechaIngreso },
        {
            title: t('tableNominaDetail.columns.expositionDate'),
            render: (row) => row.fechaInicioExposicion
        },
        { title: t('tableNominaDetail.columns.sector'), render: (row) => row.sector },
        { title: t('tableNominaDetail.columns.Job'), render: (row) => row.puesto },
        {
            title: t('tableNominaDetail.columns.risk'),
            render: (row) => (row.riesgos ? row.riesgos : 'No expuesto')
        }
    ];

    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                spacing={3}
                sx={{
                    '& .MuiGrid-root': {
                        width: '100%'
                    }
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <ExpandableItemWrapper sx={{ background: 'white', pl: '58px', mt: '-0px' }}>
                        <TitleWrapper>
                            <Typography variant="h3" sx={{ fontWeight: '600', color: '#1E272E' }}>
                                {t('responsableData.title')}
                            </Typography>
                            <IconButton onClick={toggleExpanded} style={iconButtonStyle}>
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </TitleWrapper>
                        <Collapse in={isExpanded}>
                            {responsables?.data
                                ?.filter(
                                    (data: { cargo: { codigo: string } }) =>
                                        data?.cargo?.codigo === 'R'
                                )
                                .map(
                                    (
                                        data: {
                                            nombre: string;
                                            apellido: string;
                                            cuit: string;
                                            cargo: { nombre: string };
                                            tipoContratacion: string | { nombre: string };
                                            titulo: string;
                                            matricula: string;
                                            entidadOtorgante: string;
                                        },
                                        index: number
                                    ) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ pb: 4, pl: '1px' }}
                                            key={`${data.cuit}-${index}`}
                                        >
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.name')}:</strong>
                                                    <br />
                                                    {data.nombre || ''} {data.apellido || ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.CUIT')}:</strong>
                                                    <br />
                                                    {data.cuit || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.cargo')}:</strong>
                                                    <br />
                                                    {data.cargo?.nombre || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.status')}:</strong>
                                                    <br />
                                                    {typeof data.tipoContratacion === 'string'
                                                    ? data.tipoContratacion
                                                    : data.tipoContratacion?.nombre || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.tituloHabilitante')}:
                                                    </strong>
                                                    <br />
                                                    {data.titulo || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.matriculaNumber')}:
                                                    </strong>
                                                    <br />
                                                    {data.matricula || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.entidadOtorgante')}:
                                                    </strong>
                                                    <br />
                                                    {data.entidadOtorgante || 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                        </Collapse>
                    </ExpandableItemWrapper>
                </Box>
                <Grid container spacing={2} sx={{}}>
                    <Box sx={styles.container}>
                        <Box sx={styles.filterRow}>
                            <TextField
                                label={t('tableNominaDetail.columns.cuil')}
                                value={cuilFilter}
                                onChange={handleCuilChange}
                                variant="outlined"
                                sx={styles.filterItem}
                            />
                            <TextField
                                label={t('tableNominaDetail.columns.risk')}
                                select
                                value={expuestoFilter}
                                onChange={(e) =>
                                    setExpuestoFilter(e.target.value as unknown as boolean)
                                }
                                variant="outlined"
                                sx={styles.filterItem}
                            >
                                <MenuItem value={'true'}>{t('labels.exposed')}</MenuItem>
                                <MenuItem value={'false'}>{t('labels.notExposed')}</MenuItem>
                            </TextField>
                        </Box>

                        <Box sx={styles.buttonRow}>
                            <Button variant="text" onClick={handleClearFilter}>
                                {t('buttons.clearFilters')}
                            </Button>
                            <Button variant="outlined" onClick={handleSearch}>
                                {t('buttons.search')}
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={12}>
                    <CustomTable
                        columns={columns}
                        isLoading={isLoadingDetalle}
                        data={presentacionDetalle?.data?.data ?? []}
                        count={presentacionDetalle?.data?.count}
                        filters={filters}
                        setFilters={setFilters}
                        noInfoTitle={t('tableNominaDetail.noResultsTitle')}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        onClick={handleClickVolver}
                        style={{
                            borderRadius: '8px',
                            height: '48px',
                            width: 'auto'
                        }}
                    >
                        {t('buttons.goBack')}
                    </Button>
                </Grid>
            </Grid>
            <BackdropComponent
                loading={isLoadingDetalle || isLoadingDescarga || isLoadingCambioEstado}
            />
        </div>
    );
};

export default RarView;
