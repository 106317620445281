import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BoyRounded } from '@mui/icons-material';

export interface GenerateCertificateRequestBody {
    provisorio: boolean;
    clausulaSubrogacion: boolean;
    comision: boolean;
    razonSocial: string | null;
    cuit: string | null;
    idOrganismo: number | null;
    idsEmpleados: number[];
    selectAll: boolean;
    razonSocialDestino: string | null;
    domicilioDestino: string | null;
    fechaDesdeDestino: Date | null;
    fechaHastaDestino: Date | null;
    localidadDestino: number | null;
    provinciaDestino: number | null;
    skip: number;
    take: number;
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});


const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {

    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const employeesApi = createApi({
    reducerPath: 'employees',
    tagTypes: ['Empleados', 'EmpleadoById'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getPayroll: builder.query({
            query: (params) => ({
                url: `nomina`,
                params
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Empleados']
        }),
        generatePayrollFile: builder.mutation({
            query: (body) => ({
                url: `nomina/archivo`,
                body: body,
                method: 'POST'
            }),
        }),
        getEmployees: builder.query({
            query: (params: { idOrganismo: number; cuil?: string }) => ({
                url: `nomina/empleados`,
                params
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Empleados']
        }),
        getEmployeeQuantity: builder.query({
            query: (params: { idOrganismo: number }) => ({
                url: `nomina/organismos/${params.idOrganismo}`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Empleados']
        }),
        generateCertificate: builder.mutation({
            query: (body: GenerateCertificateRequestBody) => ({
                url: `nomina/empleados/certificado-cobertura`,
                method: 'POST',
                body: body
            })
        })
    })
});

export const {
    useGetEmployeesQuery,
    useLazyGetEmployeesQuery,
    useLazyGetEmployeeQuantityQuery,
    useGetPayrollQuery,
    useLazyGetPayrollQuery,
    useGenerateCertificateMutation,
    useGeneratePayrollFileMutation
} = employeesApi;
