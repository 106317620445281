import { Theme, createTheme } from '@mui/material/styles';
import { createPalette } from './palette';
import { commonTypography } from './typography';
import { commonComponents } from './components';

const green = '#0C6600';
const blue = '#1473E6';
const primaryLightGreen = '#5A911F'; 
const primaryLightBlue = '#1473e6'; 

const createCustomTheme = (primaryColor: string, primaryLight: string) => createTheme({
    palette: {
        ...createPalette(primaryColor),
        primary: {
            main: primaryColor,
            light: primaryLight, // Agregar el color claro aquí
        },
    },
    typography: commonTypography,
    spacing: 8,
    components: {
        ...commonComponents,
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
                containedPrimary: {
                    backgroundColor: primaryColor === green ? '#5A911F' : primaryColor,
                    '&:hover': {
                        backgroundColor: primaryColor === green ? '#0c6600eb' : '#115293',
                    },
                },
                outlinedPrimary: {
                    borderColor: primaryColor,
                    color: primaryColor,
                    '&:hover': {
                        borderColor: primaryColor === green ? '#3D6113' : '#115293',
                        backgroundColor: `rgba(${primaryColor === green ? '90, 145, 31' : '20, 115, 230'}, 0.08)`,
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: primaryColor,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    color: primaryColor,
                },
                h2: {
                    color: primaryColor,
                },
            },
        },
    },
});

export const greenTheme: Theme = createCustomTheme(green, primaryLightGreen);
export const blueTheme: Theme = createCustomTheme(blue, primaryLightBlue);
