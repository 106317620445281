import { useState } from 'react';

const useToggle = (initialState = false, externalSetter?: (state: boolean) => void) => {
    const [state, setState] = useState(initialState);

    const toggle = () => {
        const newState = !state;
        setState(newState);
        if (externalSetter) {
            externalSetter(newState);
        }
    };
    
    return [state, toggle] as const;
};

export default useToggle;