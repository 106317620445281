import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Presentacion  } from '../../pages/Rar/types/types';

interface PresentacionItemState {
  presentacionItem: Presentacion | null;
}

const initialState: PresentacionItemState = {
    presentacionItem: null,
};

const rarPresentacion = createSlice({
  name: 'rarPresentacion',
  initialState,
  reducers: {
    setPresentacionItemSelected: (state, action: PayloadAction<Presentacion>) => {
      state.presentacionItem = action.payload;
    },
    clearPresentacionItemSelected: (state) => {
      state.presentacionItem = null;
    },
  },
});

export const { setPresentacionItemSelected, clearPresentacionItemSelected } = rarPresentacion.actions;
export default rarPresentacion.reducer;
