import { authApi } from '../features/auth/authApi';
import { companyApi } from '../services/companyApi';
import { presentacionesApi } from '../services/presentacionesApi';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { createUsuarios } from '../services/CreateUser';
import { newsApi, newsGestorApi } from '../services/newsApi';
import { notificationsApi } from '../services/notificationsApi';
import { persistStore, persistReducer, PERSIST, PURGE, REHYDRATE } from 'redux-persist';
import { usersApi } from '../services/usersApi';
import applicationSlice from '../features/slices/applicationSlice';
import authReducer from '../features/auth/authSlice';
import storage from 'redux-persist/lib/storage';
import { rolesApi } from '../services/rolesApi';
import themeReducer from './slices/themeSlice';
import { presentacionesRarApi } from '../services/presentacionesRarApi';
import filesReducer from './slices/filesSlice';
import responsableReducer from './slices/responsableSlice';
import rarPresentacionReducer from './slices/rarPresentacionSlice';
import datosParametricosReducer from './slices/datosParametricosSlice';
import establishmentReducer from './slices/establishmentSlice';
import gremioContratistaReducer from './slices/gremioContratistaSlice';
import { organismsApi } from '../services/organismsApi';
import { employeesApi } from '../services/employeesApi';
import { helpCenterApi } from '../services/helpCenterApi';
import { workPositionsApi } from '../services/workPositionsApi';
import { nominaApi } from '../services/nominaApi';

const persistConfig = {
    key: 'root',
    storage,
};

const authPersistConfig = {
    key: 'auth',
    storage,
  };

  const establishmentPersistConfig = {
    key: 'establishment',
    storage,
  };

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedEstablishmentReducer = persistReducer(establishmentPersistConfig, establishmentReducer);


export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [createUsuarios.reducerPath]: createUsuarios.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        empresa: companyApi.reducer,
        auth: persistedAuthReducer,
        theme: themeReducer,
        application: applicationSlice,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [newsApi.reducerPath]: newsApi.reducer,
        [newsGestorApi.reducerPath]: newsGestorApi.reducer,
        roles: rolesApi.reducer,
        [presentacionesRarApi.reducerPath]: presentacionesRarApi.reducer,
        files: filesReducer,
        [presentacionesApi.reducerPath]: presentacionesApi.reducer,
        [organismsApi.reducerPath]: organismsApi.reducer,
        [nominaApi.reducerPath]: nominaApi.reducer,
        [employeesApi.reducerPath]: employeesApi.reducer,
        [helpCenterApi.reducerPath]: helpCenterApi.reducer,
        [workPositionsApi.reducerPath]: workPositionsApi.reducer,
        responsable: responsableReducer,
        rarPresentacion: rarPresentacionReducer,
        datosParametricos: datosParametricosReducer,
        establishment: persistedEstablishmentReducer,
        gremioContratista: gremioContratistaReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(authApi.middleware)
            .concat(createUsuarios.middleware)
            .concat(usersApi.middleware)
            .concat(companyApi.middleware)
            .concat(notificationsApi.middleware)
            .concat(newsApi.middleware)
            .concat(newsGestorApi.middleware)
            .concat(rolesApi.middleware)
            .concat(presentacionesRarApi.middleware)
            .concat(presentacionesApi.middleware)
            .concat(organismsApi.middleware)
            .concat(nominaApi.middleware)
            .concat(employeesApi.middleware)
            .concat(helpCenterApi.middleware)
            .concat(workPositionsApi.middleware)
            .concat(
                createStateSyncMiddleware({
                    blacklist: [PURGE, PERSIST, REHYDRATE],
                    broadcastChannelOption: { type: 'localstorage' }
                })
            )
});

initMessageListener(store);
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
