import { borderBottom } from "@mui/system";
import { alpha } from '@mui/material/styles';

export const commonComponents = {
     MuiLink: {
        styleOverrides: {
            root: {
                color: 'var(--primary-light)',
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
    },
    MuiStack: {
        styleOverrides: {
            root: {
                '& > :not(style) + :not(style)': {
                    marginBottom: 0,
                    margintop: 0,
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                margin: '3px',
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                backgroundColor: 'white',
            },
        },
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                backgroundColor: '#476773',
                color: 'white',
                fontWeight: '600',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            head: {
                backgroundColor: '#384a52',
                color: 'white',
                fontWeight: '600',
            },
            root: {
                borderBottom: 'none',
            }
        },
    },
    MuiFormControlLabel: {
        styleOverrides :{
            root: {
                marginLeft :'0px',
                marginRight:'0px',
            }
        }
    }
};
