
import * as XLSX from 'xlsx';

const useExportToExcel = () => {
    const exportToExcel = (data: any[], headers: string[], fileName: string) => {
        const worksheetData = [headers, ...data];
        const ws = XLSX.utils.aoa_to_sheet(worksheetData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    return { exportToExcel };
};
export default useExportToExcel;

