export const commonTypography = {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
        fontSize: '2.125rem',
        fontWeight: 500,
        marginBottom: '10px',

    },
    h2: {
        fontSize: '1.75rem',
        fontWeight: 500,
        marginBottom: '10px',

    },
    h3: {
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '10px',

    },
    h4: {
        fontSize: '1.25rem',
        fontWeight: 500,
        marginBottom: '10px',
    },
    h5: {
        fontSize: '1rem',
        fontWeight: 500,
    },
    h6: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    body1: {
        fontSize: '15px',
    },
    body2: {
        fontSize: '0.835rem',
        lineHeight: 1.63,
        color: '#384A52',
    },
    button: {
        fontSize: '0.875rem',
        textTransform: 'capitalize' as 'capitalize',
    },
    subtitle1: {
        color: '#1E272E',
        fontWeight: 'bold',
        fontSize: '1.2rem',
    },
    subtitle2: {
        marginTop: '10px !important',
        color: '#6D97A3',
    },
};
