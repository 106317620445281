import React, { useEffect, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Box, Grid, Stack, MenuItem, Menu, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import NoPresentationsSIMP from '../../assets/image/empty-state-establecimientos-simp.png';
import NoPresentationsBO from '../../assets/image/empty-state-establecimientos-backoffice.png';
import ToggleStateIcon from '@mui/icons-material/ToggleOffOutlined';
import PencilIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import useUserAccess from '../../hooks/useUserAccess';
import { useRoles } from '../../hooks/useRoles';
import DynamicData from './components/DynamicData';
import { useTranslation } from 'react-i18next';
import usePresentations from './hooks/usePresentations';
import DataTable from '../../components/DataTable/DataTable';
import useGlobalFilters from '../../hooks/useGlobalFilters';
import { downloadBase64File, getDate } from '../../utils/common';
import TableActionButton from './components/TableActionButton';
import { PresentationStateChip } from './components/PresentationStateChip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
    ALERT_CONFIRM_ACTIONS,
    ALERT_TITLES,
    ALERT_TYPE,
    ALERT_TYPES,
    PRESENTATION_STATE
} from './consts';
import { generateEstablishmentFields, generateOrganismFields } from './utils/establishmentFields';
import BackdropComponent from '../../components/Backdrop/BackdropComponent';
import { useUpdatePresentationStates } from './hooks/useUpdatePresentationStates';
import { AlertDialog } from '../../components/Alert/AlertDialog';
import useReports, { PdfType } from './hooks/useReports';
import { ExpandableContent } from '../../components/Cabeceras/InfoHeaderWithExpandableItems';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setPresentacionItemSelected } from '../../redux/slices/rarPresentacionSlice';
import { useLazyGetPresentacionRarPDFbyIdQuery } from '../../services/presentacionesRarApi';
import { useGetAllPresentacionesQuery, useGetTiposPresentacionesQuery, useLazyGetPresentacionRGRLPDFbyIdQuery } from '../../services/presentacionesApi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import useUserFeatures from '../../hooks/useUserFeatures';
import SectionHeader from '../../components/SectionHeader';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import FiltersComponent from './components/FiltersComponent';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';


const columnTitles = ({ t }: any): string[] => [
    
    t('table.columns.tipo'),
    t('table.columns.contrato'),
    t('table.columns.establecimiento'),    
    t('table.columns.presentationDate'),
    t('table.columns.state'),
    t('table.columns.actions')
];
const columnWidths = ['20%','20%', '20%','20%','15%', '5%'];

const PresentacionesModuleIndex: React.FC = () => {
    const {FEATURES} = useUserFeatures();
    const dispatch = useAppDispatch();
    const [selectedTipo, setSelectedTipo] = useState<string | ''>(''); 
    const [isShowMoreFiltersOpen, setIsShowMoreFiltersOpen] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [processPdfDownload, setProccessPdfDownload] = useState(false);
    const { isAdminBO, isSupervisorSIMP, isOperatorSIMP, isViewerBO } = useRoles();
    const { isMobile, isTablet } = useIsMobile();
    const { t } = useTranslation('presentaciones');
    const navigate = useNavigate();
    const access = useUserAccess();
    const [adminSelectedItem, setAdminSelectedItem] = useState<any>(null);
    const [triggerGetPresentacionRarPDFById, { data: pdfRarData, isLoading: isLoadingRar, error: rarError }] = useLazyGetPresentacionRarPDFbyIdQuery();
    const [triggerGetPresentacionRGRLPDFById, { data: pdfRgrlData, isLoading: isLoadingRgrl, error: rgrlError }] = useLazyGetPresentacionRGRLPDFbyIdQuery();
    const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);
    
    const {
        filters,
        setFilters,
        extraFilters,
        handleSubmitSearch,
        handleChangeExtraFilters,
        debounceSearch,
        clearFilters,
    } = useGlobalFilters();

    const {
        selected,
        openCard,
        menuOpen,
        menuOpenAdmin,
        edittingPresentationState,
        establishmentDetail,
        presentationsAllData,
        isLoadingAllPresentationsData,
        isFetchingAllPresentationsData,
        anchorEl,
        setAnchorEl,
        refetchAllPresentations,
        handleClickAction,
        handleClickActionAdmin,
        setSelected,
        handleCloseMenu,
        handleCloseMenuAdmin,
    } = usePresentations({ filters: filters });

    
    const itemPresentationData = (data: any): { data: { data: any[], count:number } } => {
        if (Array.isArray(data?.data)) {
            return { data: { data: data.data, count:data.count } }; 
        }
        return { data: {
            data: [],
            count: 0
        } }; 
    };
    
        const itemTable = itemPresentationData(presentationsAllData);

    const {
        showConfirmActionAlert,
        alertType,
        updatingPresentationState,
        updatePresentationStateHandler,
        setShowConfirmActionAlert,
        setAlertType
    } = useUpdatePresentationStates();

    const {
        handleDownloadPdf,
        isLoading
    } = useReports(selected?.id, establishmentDetail?.nombre, establishmentDetail?.codigoSRT);

 

    const handleDownloadPresentation = async (item: any) => {
        try {
            setDownloadingPDF(true)
            if(!item?.id) return;
            const pdfType = item?.tipo?.nombre;
            let fileName = `${establishmentDetail?.nombre || 'Establecimiento'} - Cod SRT_${establishmentDetail?.codigoSRT || 'CodigoSRT'} -  ${pdfType}`; 
            let response: any = null;
            if (pdfType === PdfType.RGRL) {
                response = await triggerGetPresentacionRGRLPDFById({ idPresentacion: item?.id, typeId: item?.tipo?.id });
            } else if (pdfType === PdfType.RAR) {
                response = await triggerGetPresentacionRarPDFById({ idPresentacion: item?.id, typeId: item?.tipo?.id });
            }
            if(response?.data?.data?.presignedUrls?.length >= 1){
                response.data.data.presignedUrls.forEach((url: string, index: number) => {
                    downloadBase64File(url, item.id, fileName, index)
                });
            }
        } catch (error) {
        } finally {
            setDownloadingPDF(false)
        }
    }

    const getTranslatedPresentationType = (id: number, t: any) => {
        switch (id) {
            case 1:
                return t('types.rgrl_351');
            case 2:
                return t('types.rgrl_617');
            case 3:
                return t('types.rgrl_911');
            case 4:
                return t('types.rar');
            default:
                return '-';
        }
    };

    const handleEditClick = (tipoId: number, item: any, tipoTitulo:string) => {
        if (!item?.id) return;
        const basePath = './editar';
        const isTipoRar = tipoId === 4;
        const selectedEstablishmentId = item.establecimiento.id; 
        const state = {
            selectedPresentationId: item.id,
            selectedEstablishmentId,
            tipoId,
            tipoTitulo,
        };
        const path = isTipoRar ? `${basePath}-rar/${item.id}` : basePath;
        isTipoRar && dispatch(setPresentacionItemSelected(item));
        navigate(path, { state });
    };


    const handleViewClick = (tipoId: number, item: any, tipoTitulo: string) => {
        if (!item?.id) return;
        const basePath = '/backoffice/presentaciones/visualizar'; 
        const isTipoRar = tipoId === 4;
        const selectedEstablishmentId = item.establecimiento.id; 
        const state = {
            selectedPresentationId: item.id,
            selectedEstablishmentId: item.establecimiento.id,
            tipoId,
            tipoTitulo,
        };
        const path = isTipoRar ? `${basePath}-rar/${item.id}` : basePath;
        isTipoRar && dispatch(setPresentacionItemSelected(item)); 
        navigate(path, { state }); 
    };

    useEffect(() => {
        if (updatingPresentationState === false) refetchAllPresentations();
    }, [updatingPresentationState]);

    useEffect(() => {
        if (processPdfDownload && selected?.id) {
            handleDownloadPdf(selected.tipo?.id);
            setProccessPdfDownload(false);
        }
    }, [processPdfDownload, selected, handleDownloadPdf]);

    
    const generateIconDynamic = (tipoId: number | undefined): React.ElementType => {
        return tipoId !== 4 ? PencilIcon : RemoveRedEyeOutlinedIcon;
    };

    
    return (
        <>
        <Grid container>
                <Grid item xs={12}>
                    <SectionHeader>
                        <SectionHeader.Title>{t('presentaciones.title')}</SectionHeader.Title>
                        <Grid display="flex" direction={'row'}>                                         
                        </Grid>
                    </SectionHeader>
                </Grid>
                <Grid item xs={12}>

                <Grid item xs={12}>
                    <SearchToolbar
                        onChange={debounceSearch}
                        inputSearchName="nombre"
                        label={t('filters.establishmentName')}
                        onClick={handleSubmitSearch}
                        clearFilters={clearFilters}
                        showSearchInput={false}
                        showMoreFiltersButton={true}
                        isShowMoreFiltersOpen={isShowMoreFiltersOpen}
                        handleShowMoreFiltersPress={() =>
                        setIsShowMoreFiltersOpen((prevState: boolean) => !prevState)
                        }
                    >
                        <FiltersComponent
                       extraFilters={extraFilters}
                       handleChangeExtraFilters={handleChangeExtraFilters}
                       isShowMoreFiltersOpen={isShowMoreFiltersOpen}
                        />
                    </SearchToolbar>
                    </Grid>
                </Grid>
            </Grid>

            {showConfirmActionAlert && !!alertType && (
                <AlertDialog
                    title={ALERT_TITLES[alertType]}
                    cancelText={t('alerts.cancel') ?? ''} 
                    icon="info"
                    confirmAction={() =>
                        updatePresentationStateHandler(
                            selected?.id,
                            ALERT_CONFIRM_ACTIONS[alertType]
                        )
                    }
                    cancelAction={() => {
                        setShowConfirmActionAlert(false);
                        setAlertType(null);
                    }}
                    closeAction={() => {
                        setShowConfirmActionAlert(false);
                        setAlertType(null);
                    }}
                    loading={updatingPresentationState}
                />
            )}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{ marginTop: 3 }}
                spacing={3}
            >
                {openCard && (isMobile || isTablet) ? (
                    ''
                ) : (
                    <Grid item xs={openCard ? 6 : 12} lg={openCard ? 8 : 12}>
                        {(itemTable?.data?.data.length === undefined &&
                            !isFetchingAllPresentationsData) 
                         ? (
                            <Box
                                my={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        '& h4': {
                                            fontSize: '18px'
                                        },
                                        '& p': {
                                            maxWidth: '360px',
                                            textAlign: 'center',
                                            margin: 0,
                                            fontSize: '16px'
                                        }
                                    }}
                                >
                                    <img
                                        src={isAdminBO ? NoPresentationsBO : NoPresentationsSIMP}
                                        alt={t('noRecords.imageAlt') ?? ''}
                                    />
                                    <h4>{t('noRecords.title')}</h4>
                                    <p>{t('noRecords.message')}</p>
                                </Stack>
                            </Box>
                        ) : (
                            <DataTable
                                columnTitles={columnTitles({ t })}
                                columnWidths={columnWidths}
                                onSelectRow={() => {}}
                                isLoading={isLoadingAllPresentationsData}
                                items={itemTable}
                                filters={filters}
                                setFilters={setFilters}
                                isFetching={isFetchingAllPresentationsData}
                                noPaginated={false}
                                selected={selected}
                                headers={[
                                    
                                    {
                                        type: 'data',
                                        upperLabel: (item: any) =>
                                            item?.tipo?.id
                                                ? getTranslatedPresentationType(item.tipo.id, t)
                                                : '-'
                                    },
                                    {
                                        type: 'data',
                                        upperLabel: (item: any) =>
                                            item?.contrato
                                                ? `${item.contrato.cuitOrganismoCentral} - ${item.contrato.razonSocial}`
                                                : '-'
                                    },
                                    {
                                        type: 'data',
                                        upperLabel: (item: any) =>
                                            item?.establecimiento
                                                ? `${item.establecimiento.nombre} (Código SRT: ${item.establecimiento.codigoSRT})`
                                                : '-'
                                    },
                                    {
                                        type: 'data',
                                        upperLabel: (item: any) =>
                                            (item?.fechaPresentacion &&
                                                getDate(item?.fechaPresentacion)) ||
                                            '-'
                                    },
                                    {
                                        type: 'data',
                                        upperLabel: (item: any) =>
                                            item?.idEstado ? (
                                                <PresentationStateChip
                                                    presentationState={item?.idEstado}
                                                />
                                            ) : (
                                                '-'
                                            )
                                    },
                                    {
                                        type: 'presentation-options',
                                        onClick: (e: any, item: any) => {
                                            setAnchorEl(e.currentTarget);
                                            setSelected((prevState) => item);
                                        },
                                        upperLabel: (item: any, e: any) => (
                                            <Box display={'flex'} gap={1}>
                                               {FEATURES.GESTION_PRESENTACIONES.hasAccess && (
                                               
                                               <TableActionButton
                                                    displayOption={
                                                        (isOperatorSIMP || isSupervisorSIMP) &&
                                                        item?.idEstado !== PRESENTATION_STATE.BORRADOR && 
                                                        item?.idEstado !== PRESENTATION_STATE.PROCESANDO &&
                                                        item?.idEstado !== PRESENTATION_STATE.ERROR_PROCESAMIENTO
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            item?.idEstado ===
                                                            PRESENTATION_STATE.CANCELADO
                                                        ) {
                                                            return;
                                                        }
                                                        handleDownloadPresentation(item)
                                                    }}
                                                    disabled={
                                                        item?.idEstado ===
                                                        PRESENTATION_STATE.CANCELADO || downloadingPDF
                                                    }
                                                    IconComponent={PictureAsPdfOutlinedIcon }
                                                />
                                            )}

                                               {FEATURES.EDICION_PRESENTACION.hasAccess && (
                                                <TableActionButton
                                                    displayOption={isAdminBO}
                                                    onClick={(event: any) => {
                                                        if (
                                                            item?.idEstado ===
                                                            PRESENTATION_STATE.CANCELADO
                                                        ) {
                                                            return;
                                                        }
                                                        handleClickAction(item, event);
                                                    }}
                                                    disabled={
                                                        item?.idEstado ===
                                                        PRESENTATION_STATE.CANCELADO
                                                    }
                                                    IconComponent={ToggleStateIcon}
                                                />
                                            )}

                                                 {FEATURES.ALTA_PRESENTACION.hasAccess && (
                                                <TableActionButton
                                                   displayOption={
                                                    (isOperatorSIMP || isSupervisorSIMP) &&
                                                    (item?.idEstado === PRESENTATION_STATE.BORRADOR || 
                                                    item?.idEstado === PRESENTATION_STATE.ERROR_PROCESAMIENTO ||                                                         
                                                     item?.idEstado === PRESENTATION_STATE.PROCESANDO)
                                                }
                                                    disabled={
                                                        item?.idEstado ===
                                                        PRESENTATION_STATE.CANCELADO
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            item?.idEstado ===
                                                            PRESENTATION_STATE.CANCELADO
                                                        ) {
                                                            return;
                                                        }
                                                        setSelected(item);
                                                        handleEditClick(item.tipo?.id, item, item.tipo?.titulo);
                                                    }}
                                                    IconComponent={generateIconDynamic(
                                                        item.tipo?.id
                                                    )}
                                                />
                                                 )}

                                                <TableActionButton
                                                    displayOption={isAdminBO || isViewerBO }
                                                    onClick={(event: any) => {
                                                        setAdminSelectedItem(item);
                                                        handleClickActionAdmin(item, event);
                                                    }}
                                                  
                                                    IconComponent={ExpandMoreIcon }
                                                />

                                            </Box>
                                        )
                                    }
                                ]}
                            />
                        )}
                    </Grid>
                )}
                {openCard && (
                    <Grid
                        item
                        xs={!isMobile && !isTablet ? 6 : 12}
                        lg={!isMobile && !isTablet ? 4 : 12}
                    ></Grid>
                )}
            </Grid>

           
            <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
           
                <Box>
               
                    <MenuItem
                        disabled={selected?.estado === PRESENTATION_STATE.EN_REVISION}
                        onClick={() => {
                            setAlertType(ALERT_TYPES.CONFIRM_REVISE as ALERT_TYPE);
                            handleCloseMenu();
                        }}
                    >
                         {t('states.en_revision')}
                    </MenuItem>
                    <MenuItem
                        disabled={selected?.estado === PRESENTATION_STATE.PENDIENTE_VISITA}
                        onClick={() => {
                            setAlertType(ALERT_TYPES.CONFIRM_VISIT as ALERT_TYPE);
                            handleCloseMenu();
                        }}
                    >
                         {t('states.pendiente_visita')}
                    </MenuItem>
                    <MenuItem
                        disabled={selected?.estado === PRESENTATION_STATE.PRESENTADO}
                        onClick={() => {
                            setAlertType(ALERT_TYPES.CONFIRM_CONFORM as ALERT_TYPE);
                            handleCloseMenu();
                        }}
                    >
                        {t('states.presentado')}
                        
                    </MenuItem>
                </Box>
               
            </Menu>
            

            <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpenAdmin} onClose={handleCloseMenuAdmin}>
                <Box>

                    <MenuItem
                        disabled={
                            adminSelectedItem?.idEstado ===
                            PRESENTATION_STATE.CANCELADO || downloadingPDF
                            }
                        onClick={() => {
                            if (
                                adminSelectedItem?.idEstado ===
                                PRESENTATION_STATE.CANCELADO
                            ) {
                                return;
                            }
                            handleDownloadPresentation(adminSelectedItem)
                        }}
                    >
                        {t('actions.download')}
                    </MenuItem>
                  
                    {FEATURES.VISUALIZACION_DE_DETALLE_PRESENTACION.hasAccess && (
                    <MenuItem
                            disabled={
                                adminSelectedItem?.idEstado ===
                                PRESENTATION_STATE.CANCELADO
                            }
                        onClick={() => {
                            if (adminSelectedItem?.idEstado === PRESENTATION_STATE.CANCELADO) {
                                return;
                            }                        
                            handleViewClick(
                                adminSelectedItem?.tipo?.id,
                                adminSelectedItem,
                                adminSelectedItem?.tipo?.titulo
                            );
                            handleCloseMenu();
                        }}
                    >
                        {t('actions.view')}
                    </MenuItem>
                    )}
                    
                    {FEATURES.ELIMINACION_PRESENTACION.hasAccess && (
                    <MenuItem
                    disabled={
                        adminSelectedItem?.idEstado ===
                        PRESENTATION_STATE.CANCELADO
                    }
                        onClick={() => {
                            if (
                                adminSelectedItem?.idEstado ===
                                PRESENTATION_STATE.CANCELADO
                            ) {
                                return;
                            }
                            handleCloseMenu();
                            if (selected) {
                                setAlertType(ALERT_TYPES.CONFIRM_CANCEL as ALERT_TYPE); 
                            }
                        }}
                    >
                        {t('actions.cancel')}
                    </MenuItem>
                    )}

                    
                </Box>
            </Menu>
            <BackdropComponent loading={edittingPresentationState || isLoading} />
        </>
    );
};

export default PresentacionesModuleIndex;
