import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import BackdropComponent from '../Backdrop/BackdropComponent';
import NoInfo from '../NoInfo';

interface Column {
  title: string;
  render: (row: any) => React.ReactNode;
  align?: 'left' | 'center' | 'right';
  styles?: any;
}

interface TableProps {
    columns: Column[];
    data: any[];
    isLoading: boolean;
    filters?: any;
    setFilters?: any;
    noPaginated?: boolean;
    count?: number;
    noInfoTitle?: any;
    noInfoSubtitle?: any;
    noInfoText?: string;
    testID?: string;
}

const getCellStyles = (align?: 'left' | 'center' | 'right') => {
  if (!align) {
    return {};
  }

  return {
    display: 'flex',
    justifyContent: align === 'center' ? 'center' : align === 'right' ? 'flex-end' : 'flex-start',
  };
};


const CustomTable: React.FC<TableProps> = ({ columns, data, isLoading, noPaginated, count, filters, setFilters, noInfoTitle, noInfoSubtitle, noInfoText = 'noItems', testID='table' }) => {
  const { t } = useTranslation('userForm');
  const [rowsPerPage, setRowsPerPage] = useState(filters?.take || 10);
  const { t: tCommon } = useTranslation('common');

  const [page, setPage] = useState(0);
  // const rowsPerPage = filters?.take || 10;

  useEffect(() => {
    if (filters?.skip === 0) {
      setPage(0);
    }
  }, [filters?.skip]);

  useEffect(() => {
    if (setFilters) {
      setFilters({
        ...filters,
        skip: page * rowsPerPage,
        take: rowsPerPage,
      });
    }
  }, [page, rowsPerPage, setFilters]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setFilters((prevFilters: any) => ({ ...prevFilters, take: newRowsPerPage, skip: 0 }));
};

  return (
    <>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell 
                key={index}
                style={{
                  backgroundColor: '#384A52',
                  color: 'white',
                  width: column.styles?.width ?? 'auto',
                  paddingLeft: 20,
                  whiteSpace: column.styles?.whiteSpace ?? 'normal',
                }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <CircularProgress size="small" />
              </TableCell>
            </TableRow>
          ) : (
            data.map((row, rowIndex) => (
              <TableRow key={row.id}>
                {columns.map((column, colIndex) => (
                  <TableCell key={colIndex} style={getCellStyles(column.align)}>
                    {column.render(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {!noPaginated && !(data?.length < 1) && count && ( 
                  <TablePagination
                      labelDisplayedRows={({ from, to, count }) => {
                          return `${from}–${to} ${t('of')} ${count} items`;
                      }}
                      component="div"
                      count={count || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage={tCommon('table.pagination.rowsPerPage')}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={(event) =>
                        handleChangeRowsPerPage(parseInt(event.target.value, 10))
                    }
                  />
              )}
              {data?.length < 1 && !isLoading && (
                <NoInfo text={noInfoText} title={noInfoTitle} subtitle={noInfoSubtitle} />
              )}
    </TableContainer>
    <BackdropComponent loading={isLoading} />
  </>

  )
};

export default CustomTable;
