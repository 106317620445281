import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BASE_URLS } from './consts';

interface AssignEstablishmentsRequest {
    idUsuario: number;
    idsEstablecimientos: number[];
}


interface CompanyRolesResponse {
    perfiles: [
        {
            id: number;
            nombre: string;
        }
    ];
    categoriasFiscales: [
        {
            id: number;
            nombre: string;
        }
    ];
    organizaciones: [
        {
            id: number;
            abreviatura: string;
            colorPrincipal: string;
            imagenes: string;
            nombre: string;
        }
    ];
}

interface Establishment {
    id: number;
    nombre: string;
    direccion: {
        calle: string;
        altura: string;
        ciudad: {
            id: number;
            nombre: string;
        };
    };
    selected: boolean;
}

interface EstablishmentsResponse {
    establecimientos: Establishment[];
}

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const companyApi = createApi({
    reducerPath: 'empresa',
    tagTypes: ['Empresas', 'EmpresaById'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createEstablishment: builder.mutation({
            query: (body) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}`,
                method: 'POST',
                body: body
            })
        }),
        editEmpresa: builder.mutation({
            query: ({ body, idEmpresa }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/${idEmpresa}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas', 'EmpresaById'])
        }),
        editEmpresaState: builder.mutation({
            query: ({ body, idEmpresa }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/${idEmpresa}/estado`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas', 'EmpresaById'])
        }),
        deRegisterEstaglishmentState: builder.mutation({
            query: ({ body, establishmentId }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/${establishmentId}/baja`,
                method: 'POST',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas', 'EmpresaById'])
        }),
        getEmpresas: builder.query({
            query: (params) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}`,
                params
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Empresas']
        }),
        getCertificatesByIdBuque: builder.query({
            query: (idBuque) => ({ url: `buques/${idBuque}/certificados` }),
            transformResponse: (response: { data: any }) => response?.data
        }),
        getEmpresa: builder.query({
            query: (idEmpresa) => ({ url: `${BASE_URLS.ESTABLISHMENTS}/${idEmpresa}` }),
            providesTags: ['EmpresaById'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        getComunicaciones: builder.query({
            query: ({ skip, take, nombre, fechaInicio, fechaFin }) => ({
                url: 'notificaciones',
                params: { skip, take, nombre, fechaInicio, fechaFin }
            }),
            providesTags: ['Empresas']
        }),
        editComunicacionCertificado: builder.mutation({
            query: ({ body, idCertificado }) => ({
                url: `/empresas/comunicaciones/certificados/${idCertificado}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas'])
        }),
        getComunicacionesEventos: builder.query({
            query: () => ({ url: '/empresas/comunicaciones/eventos' }),
            providesTags: ['Empresas']
        }),
        editComunicacionEventos: builder.mutation({
            query: ({ body, id }) => ({
                url: `/empresas/comunicaciones/eventos/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas'])
        }),
        editComunicacionEventosUsers: builder.mutation({
            query: ({ body, id }) => ({
                url: `/empresas/comunicaciones/eventos/${id}/usuarios`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas'])
        }),
        editComunicacionEventosPerfil: builder.mutation({
            query: ({ body, idParametroEventoPerfil }) => ({
                url: `/empresas/comunicaciones/eventos/perfil/${idParametroEventoPerfil}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: (_, error) => (error ? [] : ['Empresas'])
        }),
        assignEstablishmentsToUser: builder.mutation({
            query: ({ idUsuario, idsEstablecimientos }: AssignEstablishmentsRequest) => ({
                url: `/establecimientos/usuarios/${idUsuario}`,
                method: 'POST',
                body: { idsEstablecimientos }
            }),
            invalidatesTags: ['EmpresaById']
        }),
        getEstablishmentDetail: builder.query({
            query: ({ establishmentId }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/${establishmentId}`
            }),
            providesTags: ['EmpresaById'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        getCIIUCodes: builder.query({
            query: (params) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/codigos-ciiu`,
                params
            }),
            providesTags: ['Empresas']
        }),
        getProvinces: builder.query({
            query: () => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/provincias`,
                transformResponse: (response: { data: any }) => response?.data?.provincias
            })
        }),
        getCities: builder.query({
            query: ({ provinceId }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/provincias/${provinceId}/ciudades`
            }),
            providesTags: ['Empresas']
        }),
        getPostalCodes: builder.query({
            query: ({ cityId }) => ({
                url: `${BASE_URLS.ESTABLISHMENTS}/ciudades/${cityId}/codigos-postal`
            }),
            providesTags: ['Empresas']
        }),
        getEstablishmentsByUser: builder.query<EstablishmentsResponse, { idUsuario: number }>({
            query: ({ idUsuario }) => ({
                url: `/establecimientos/usuarios/${idUsuario}`
            }),
            providesTags: ['EmpresaById'],
            keepUnusedDataFor: 5,
            transformResponse: (response: { data: any }) => response.data
        }),
        getEstablishmentsIndicators: builder.query({
            query: () => ({
                url: `/establecimientos/indicadores`
            }),
            providesTags: ['Empresas'],
            transformResponse: (response: { data: any }) => response?.data
        })
    })
});

export const {
    useGetEmpresaQuery,
    useCreateEstablishmentMutation,
    useEditEmpresaMutation,
    useDeRegisterEstaglishmentStateMutation,
    useEditEmpresaStateMutation,
    useGetEmpresasQuery,
    useLazyGetEmpresasQuery,
    useLazyGetEmpresaQuery,
    useGetComunicacionesQuery,
    useEditComunicacionCertificadoMutation,
    useGetComunicacionesEventosQuery,
    useEditComunicacionEventosMutation,
    useEditComunicacionEventosUsersMutation,
    useEditComunicacionEventosPerfilMutation,
    useAssignEstablishmentsToUserMutation,
    useGetEstablishmentDetailQuery,
    useGetCIIUCodesQuery,
    useGetProvincesQuery,
    useLazyGetProvincesQuery,
    useGetCitiesQuery,
    useLazyGetCitiesQuery,
    useGetPostalCodesQuery,
    useLazyGetPostalCodesQuery,
    useGetEstablishmentsByUserQuery,
    useGetEstablishmentsIndicatorsQuery
} = companyApi;
