import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

type NoInfoProps = {
    text?: string;
    image?: string;
    title?: string;
    subtitle?: string;
};

function NoInfo({ text, image, title, subtitle }: NoInfoProps) {
    const { t } = useTranslation('userForm');
    return (
        <Box sx={{ margin: '15px auto', textAlign: 'center' }}>
            {image && (
                <Box component="img" src={image} alt="No info" sx={{ maxWidth: '100%', height: 'auto' }} />
            )}
            {(title || text) && (
                <Typography variant="h4" component="div" sx={{ marginTop: '35px', fontWeight: 'bold' }}>
                    {title || t(text || 'noinfo')}
                </Typography>
            )}
            {subtitle && (
                <Typography variant="subtitle1" component="div" sx={{ marginTop: '10px', color: 'text.secondary' }}>
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
}

export default NoInfo;
