import { memo } from 'react';
import { TableRow, TableCell } from '@mui/material';
import DataTableCell from './DataTableCell';
import styles from './styles.module.css';

function DataTableRow(props: any) {
    const { item, headers, onSelectRow, selected, selectedItemFromAction } = props;

    return (
        <TableRow className={styles['datatable-rows']} selected={item?.id === selected?.id}>
            {headers.map((header: any, subIndex: number) => (
                <TableCell
                    key={subIndex}
                    className={styles['datatable-tr-td-overflow']}
                    align={header?.type === 'action' ? 'center' : header?.align || 'left'}
                    onClick={(e) =>
                        ['action', 'selection', 'presentation-options'].includes(header?.type)
                            ? header.onClick(e, item)
                            : onSelectRow(item)
                    }
                >
                    <DataTableCell
                        onSelectRow={onSelectRow}
                        header={header}
                        item={item}
                        index={subIndex}
                        selectedItemFromAction={selectedItemFromAction}
                        {...props}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}

export default memo(DataTableRow);
