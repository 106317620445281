import { useEffect, useState } from 'react';
import {
    ALERT_TYPE,
    PRESENTATION_STATE,
    PRESENTATION_STATE_UPDATE_TYPE,
    PRESENTATION_STATE_UPDATE_TYPES
} from '../consts';
import {
    useUpdatePresentationStateMutation
} from '../../../services/presentacionesApi';
import usePresentations from './usePresentations';
import { enqueueSnackbar } from 'notistack';

const useUpdatePresentationStates = () => {
    const [showConfirmActionAlert, setShowConfirmActionAlert] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<ALERT_TYPE | null>(null);
    const [isProcessingPresentation, setLoading] = useState<boolean>(false);
    const [updatePresentationState, { isLoading: updatingPresentationState }] =
        useUpdatePresentationStateMutation();

    useEffect(() => {
        if (alertType === null) return;
        setShowConfirmActionAlert(true);
    }, [alertType]);

    const handleUpdatePresentationState = async (
        presentationId: number,
        newState: PRESENTATION_STATE
    ) => {
        try {
            setLoading(true);
            await updatePresentationState({
                presentationId: presentationId,
                body: { idEstado: newState }
            });
            enqueueSnackbar('Estado editado con éxito', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Algo salió mal', { variant: 'error' });
        } finally {
            setShowConfirmActionAlert(false);
            setAlertType(null);
            setLoading(false);
        }
    };
    

    const updatePresentationStateHandler = async (
        presentationId: number | undefined,
        actionType: PRESENTATION_STATE_UPDATE_TYPE,
        body?: any
    ) => {
        if (typeof presentationId !== 'number' || presentationId <= 0) {
            enqueueSnackbar('ID de presentación inválido', { variant: 'error' });
            return;
        }

        switch (actionType) {
            case PRESENTATION_STATE_UPDATE_TYPES.REVISE:
                await handleUpdatePresentationState(presentationId, PRESENTATION_STATE.EN_REVISION);
                break;

            case PRESENTATION_STATE_UPDATE_TYPES.VISIT:
                await handleUpdatePresentationState(
                    presentationId,
                    PRESENTATION_STATE.PENDIENTE_VISITA
                );
                break;

            case PRESENTATION_STATE_UPDATE_TYPES.CONFORM:
                await handleUpdatePresentationState(presentationId, PRESENTATION_STATE.PRESENTADO);
                break;

            case PRESENTATION_STATE_UPDATE_TYPES.CANCEL:
                await handleUpdatePresentationState(presentationId, PRESENTATION_STATE.CANCELADO);
                break;
            default:
                console.error('Unknown action type');
        }
    };

    return {
        isProcessingPresentation,
        showConfirmActionAlert,
        alertType,
        updatingPresentationState,
        updatePresentationStateHandler,
        setShowConfirmActionAlert,
        setAlertType
    };
};

export { useUpdatePresentationStates };
