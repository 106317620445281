import { useEffect, useState } from 'react';
import {
    useEditEmpresaStateMutation,
    useGetEstablishmentDetailQuery
} from '../../../services/companyApi';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import {
    useGetDatosParametricosQuery,
    useGetTiposPresentacionesQuery,
    useLazyGetPresentacionesByEstablecimientoQuery,
    useLazyGetAllPresentacionesQuery,
    useGetAllPresentacionesQuery
} from '../../../services/presentacionesApi';
import { PRESENTATION_STATE } from '../consts';
import { setParametricData } from '../../../redux/slices/datosParametricosSlice';

export interface tipo_presentaciones {
    id: number;
    nombre: string;
    detalle: string;
}
export interface rowPresentation {
    id: number;
    estado: PRESENTATION_STATE;
    tipo?: tipo_presentaciones;
}
const usePresentations = ({ filters, establishmentId }: any) => {
    const dispatch = useAppDispatch();
    const [selected, setSelected] = useState<rowPresentation | null>(null);
    const [selectedItemFromAction, setSelectedItemFromAction] = useState<any>(null);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOpenAdmin, setMenuOpenAdmin] = useState(false);
    const [editState, { isLoading: edittingPresentationState }] = useEditEmpresaStateMutation();
    const {
        data: tiposPresentaciones,
        error: tiposPresentacionesError,
        isLoading: loadingPresentationtypes
    } = useGetTiposPresentacionesQuery(establishmentId);

    
    const { data: parametricData, isLoading: isLoadingParametricos } =
        useGetDatosParametricosQuery();
    const {
        data: establishmentDetail,
        isLoading: isLoadingEstablishmentDetail,
        isFetching: isFetchingEstablishmentDetail
    } = useGetEstablishmentDetailQuery({
        establishmentId: establishmentId
    });

    const [
        getPresentationsByEstablishment,
        {
            data: presentationsData,
            error: presentationsDataError,
            isLoading: isLoadingPresentationsData,
            isFetching: isFetchingPresentationsData
        }
    ] = useLazyGetPresentacionesByEstablecimientoQuery({...filters, idEstablecimiento: establishmentId});
    

    const [
        getAllPresentations,
        {
            data: presentationsAllData,
            error: presentationsAllDataError,
            isLoading: isLoadingAllPresentationsData,
            isFetching: isFetchingAllPresentationsData,
        }
    ] = useLazyGetAllPresentacionesQuery({...filters});
    
    useEffect(() => {
        if (!isLoadingParametricos && parametricData) {
            dispatch(setParametricData(parametricData.data));
        }
    }, [isLoadingParametricos, parametricData, dispatch]);

    const handleOpenCard = (item: any) => {
        setOpenCard(true);
        setSelected(item);
    };

    const handleCloseCard = () => {
        setOpenCard(false);
        clearSelected();
    };

    useEffect(() => {
        if(!establishmentId || establishmentId <= 0) {
            return
        }
        getPresentationsByEstablishment({
            ...filters,
            idEstablecimiento: establishmentId
        });
    }, [filters, establishmentId]);

    useEffect(() => {  
        getAllPresentations({
            ...filters,
        });
    }, [filters]);


    useEffect(() => {
        if (openCard) handleCloseCard();
        // eslint-disable-next-line
    }, [filters]);

    const refetchPresentations = () => {
        if(!establishmentId || establishmentId <= 0) {
            return
        }
        getPresentationsByEstablishment({
            ...filters,
            idEstablecimiento: establishmentId
        });
    };

    const refetchAllPresentations = () => {
        getPresentationsByEstablishment({
            ...filters,
        });
    };

    const clearSelected = () => {
        setSelected(null);
        setSelectedItemFromAction(null);
    };

    const handleClickAction = (item: any, event: any) => {
        setSelected(item); 
        setAnchorEl(event.currentTarget); 
        setMenuOpen(true); 
    };
    
    const handleClickActionAdmin = (item: any, event: any) => {
        setSelected(item); 
        setAnchorEl(event.currentTarget);
        setMenuOpenAdmin(true);
    };
    

    const handleCloseMenu = () => {
        setMenuOpen(false);
        setSelectedItemFromAction(null);
    };
    const handleCloseMenuAdmin = () => {
        setMenuOpenAdmin(false);
        setSelectedItemFromAction(null);
    };

    return {
        parametricData,
        selected,
        openCard,
        selectedItemFromAction,
        anchorEl,
        menuOpen,
        menuOpenAdmin,
        presentationsData,
        presentationsDataError,
        isLoadingPresentationsData,
        isFetchingPresentationsData,
        presentationsAllData,
        presentationsAllDataError,
        isLoadingAllPresentationsData,
        isFetchingAllPresentationsData,
        establishmentDetail,
        isLoadingEstablishmentDetail,
        isFetchingEstablishmentDetail,
        tiposPresentaciones,
        tiposPresentacionesError,
        loadingPresentationtypes,
        edittingPresentationState,
        setAnchorEl,
        refetchPresentations,
        refetchAllPresentations,
        getPresentationsByEstablishment,
        getAllPresentations,
        editState,
        setSelectedItemFromAction,
        setOpenCard,
        handleClickAction,
        handleClickActionAdmin,
        handleOpenCard,
        setSelected,
        handleCloseMenu,
        handleCloseMenuAdmin,
        handleCloseCard,
    };
};

export default usePresentations;
