import { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import MainTable from './MainTable';

function DataTable({ columnTitles,columnWidths, ...props }: any) {
    const { t } = useTranslation('common');
    const { filters, setFilters, items, isLoading, noPaginated , noInfoTitle, noInfoSubtitle } = props;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangeRowsPerPage = (newRowsPerPage: number) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        setFilters((prevFilters: any) => ({ ...prevFilters, take: newRowsPerPage, skip: 0 }));
    };
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (filters?.skip === 0) setPage(0);
    }, [filters]);

    const handleChangePage = (_: any, newPage: number) => {
        const skip = filters.skip;
        const take = filters.take;
        setFilters({
            ...filters,
            skip: newPage > page ? skip + take : skip - take
        });
        setPage(newPage);
    };

    if (isLoading) return <Loading />;
    return (
        <MainTable
            {...props}
            items={items?.data?.data}
            columnTitles={columnTitles}
            columnWidths={columnWidths}
            noInfoTitle={noInfoTitle}
            noInfoSubtitle={noInfoSubtitle}
        >
            {!noPaginated && !(page === 0 && items?.data?.data?.length < filters.take) && (
                <TablePagination
                    labelDisplayedRows={({ from, to, count }) => {
                        return `${from}–${to} ${t('table.pagination.of')} ${count} items`;
                    }}
                    component="div"
                    rowsPerPage={filters.take}
                    count={items?.data?.count || 0}
                    page={items?.data?.count <= 10 ? 0 : page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage={t('table.pagination.rowsPerPage')}
                    onRowsPerPageChange={(event) =>
                        handleChangeRowsPerPage(parseInt(event.target.value, 10))
                    }
                />
            )}
        </MainTable>
    );
}

export default DataTable;
