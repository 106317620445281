import { Box, Grid, Menu, MenuItem, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { showAlert } from '../../features/slices/applicationSlice';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import DataTable from '../../components/DataTable/DataTable';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionHeader from '../../components/SectionHeader';
import useGlobalFilters from '../../hooks/useGlobalFilters';
import useUserAccess from '../../hooks/useUserAccess';
import {
    useEditComunicacionCertificadoMutation,
    useGetComunicacionesQuery
} from '../../services/companyApi';

import DateRangePickerComponent from '../../components/DateRangePickerComponent';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VisibilityIcon from '@mui/icons-material/Visibility'
import NotificationDrawer from './components/NotificationDrawer';
import { getDateTime } from '../../utils/common';


export interface rowComunicacion {
    comunicacionesCertificados: ComunicacionesCertificado[];
    remplazosMensaje: string[];
    remplazosMensajeCorto: string[];
}

export interface ComunicacionesCertificado {
    id: number;
    titulo: string;
    tipo: string;
    aviso: number;
    mensaje: string;
    mensajeCorto: string;
    mail: boolean;
    activo: boolean;
    notificacion: boolean;
    push: boolean;
    tipoCertificado: TipoCertificado;
    fechaLectura?: Date | null;
    organismo?: Organismo;
    establecimiento?: Establecimiento;
    fechaAlta?: Date;
}

export interface Organismo {
 nombre: string;
}

export interface Establecimiento {
    nombre: string;
   }
   

export interface TipoCertificado {
    id: number;
    nombre: string;
}



function NotificacionesIndex() {
    const { t } = useTranslation('communications');
    const access = useUserAccess();
    const [editComunicacionData] = useEditComunicacionCertificadoMutation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useAppDispatch();
    const { isMobile, isTablet } = useIsMobile();
    const [selected, setSelected] = useState<ComunicacionesCertificado | null>(null);
    const [mainData, setMainData] = useState<any>();
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
  
    const {
        filters,
        setFilters,
        handleSubmitSearch,
        handleChangeExtraFilters,
        debounceSearch,
        setExtraFilters,
        clearFilters
    } = useGlobalFilters();
   
    const columnTitles = [
        '',
        'Asunto',
        'Remitente',
        'Fecha',     
        'Ver'
    ];
    const columnWidths = ['5%', '40%','40%','10%', '5%'];

    const [inputText, setInputText] = useState<string | null>(null);


    const handleOpenDrawer = async (item: ComunicacionesCertificado) => {
        setSelected(item);
        setDrawerOpen(true);
    
        
        if (!item.fechaLectura) {
            try {
                const updatedItem = { ...item, fechaLectura: new Date() };
                
                const updatedData = mainData?.data?.data.map((comunicacion: ComunicacionesCertificado) =>
                    comunicacion.id === updatedItem.id ? updatedItem : comunicacion
                );
    
                setMainData({ data: { data: updatedData } });
            } catch (error) {
                enqueueSnackbar('Error al actualizar la notificación', { variant: 'error' });
            }
        }
    };
    
      const handleCloseDrawer = () => {
        setDrawerOpen(false);
        refetch(); 
    };


    const {
        data: communicationData,
        isLoading,
        refetch,
        isFetching
    } = useGetComunicacionesQuery({
        ...filters,
        fechaInicio: startDate ? startDate : undefined,
        fechaFin: endDate ? endDate : undefined,
    });

    useEffect(() => {
        if (communicationData) {
            const data = communicationData?.data?.data || [];
            const count = communicationData?.data?.count || 0;
            setMainData({ data: { data, count } });
        }
    }, [communicationData]);


 const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);

    const filtersWithDates = {
        ...filters,
        fechaInicio: startDate ? (startDate) : undefined,
        fechaFin: endDate ? (endDate) : undefined
    };

    setFilters(filtersWithDates);
    handleSubmitSearch();
};

    
    /**Searchbar */

    useEffect(() => {
    }, [mainData]);
    return (
        <>
            <SectionHeader>
                <SectionHeader.Title>{'Notificaciones'}</SectionHeader.Title>
            </SectionHeader>

            <Grid
                container
                direction={isMobile ? 'column-reverse' : 'row'}
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{
                    '& .MuiGrid-root': {
                        width: '100%'
                    }
                }}
            >
                <Grid item xs={12} sx={{ margin: '0 0 15px 0' }}>
                    <SearchToolbar
                        label="Buscar"
                        inputSearchName="nombre"
                        onClick={handleSubmitSearch}
                        clearFilters={clearFilters}
                        onChange={debounceSearch}
                        width={'70%'}
                        hiddeButtons={true}
                    >
                        <>  
                        <Box width={'50%'}>
                        <DateRangePickerComponent
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                        />
                    </Box></>
                    </SearchToolbar>

                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
            >
              
              <Grid item xs={12} lg={12}>
                    <DataTable
                    
                        columnTitles={columnTitles}
                        columnWidths={columnWidths}                        
                        headers={
                            [
                            {
                                type: 'data',
                                upperLabel: (item: ComunicacionesCertificado) => (
                                    <>
                                        {item?.fechaLectura ? (
                                            <NotificationsNoneIcon 
                                                sx={{ color: 'primary.main' }} 
                                            />
                                        ) : (
                                            <NotificationsIcon 
                                                sx={{ color: 'primary.main' }} 
                                            />
                                        )}
                                    </>
                                ),
                                align: 'left',
                                
                            
                            },
                            {
                                type: 'data',
                                upperLabel: (item: ComunicacionesCertificado) => (
                                    <Box
                                        sx={{
                                            fontWeight: item.fechaLectura ? 'normal' : 'bold',  
                                            color: item.fechaLectura ? 'inherit' : 'primary.main',
                                        }}
                                    >
                                        {item?.titulo || '-'}
                                    </Box>
                                ),
                                align: 'left'
                            },
                            {
                                type: 'data',
                                upperLabel: (item: ComunicacionesCertificado) => (
                                    <Box
                                        sx={{
                                            fontWeight: item.fechaLectura ? 'normal' : 'bold',  
                                            color: item.fechaLectura ? 'inherit' : 'primary.main',
                                        }}
                                    >
                                        {` ${item?.organismo?.nombre || ''}`}
                                    </Box>
                                ),
                                align: 'left'
                            },
                            {
                                type: 'data',
                                upperLabel: (item: ComunicacionesCertificado) => (
                                    <Box
                                        sx={{
                                            fontWeight: item.fechaLectura ? 'normal' : 'bold',  
                                            color: item.fechaLectura ? 'inherit' : 'primary.main', 
                                        }}
                                    >
                                        {getDateTime(item?.fechaAlta) || '-'}
                                    </Box>
                                ),
                                align: 'left'
                            },
                            {
                                type: 'data',
                                upperLabel: (item: ComunicacionesCertificado) => (
                                  <VisibilityIcon
                                    sx={{ color: item.fechaLectura ? 'inherit' : 'primary.main' }}  
                                    onClick={() => handleOpenDrawer(item)}
                                  />
                                ),
                                align: 'center'
                              }
                        ]}
                        rowClassName={(item: ComunicacionesCertificado) =>
                            item?.fechaLectura ? '' : 'row-without-fechaLectura'
                        }
                        onSelectRow={(item: ComunicacionesCertificado) => {
                            handleOpenDrawer(item);
                        }}
                        isLoading={isLoading}
                        items={mainData}
                        filters={filters}
                        setFilters={setFilters}
                        isFetching={isFetching}
                        selected={selected}
                    />
                </Grid>           
            </Grid>
            <NotificationDrawer 
            open={drawerOpen} 
            onClose={handleCloseDrawer} 
            data={selected} 
            />
        </>
    );
}

export default NotificacionesIndex;
