import { Drawer, Box, Typography, IconButton, Button, useTheme, Grid } from '@mui/material';
import SectionHeader from '../../../components/SectionHeader';
import { ArrowBack } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDateTime } from '../../../utils/common';
import { useSetReadMutation } from '../../../services/notificationsApi';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    setEstablishmentId,
    setEstablishmentIdFilter,
    setEstablishmentName
} from '../../../redux/slices/establishmentSlice';
import { DrawerHeader } from '../../../components/Drawer/DrawerHeader';

interface NotificationDrawerProps {
    open: boolean;
    onClose: () => void;
    data: any;
}

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({ open, onClose, data }) => {
    const { t } = useTranslation('communications');
    const themeLink = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [setRead] = useSetReadMutation();
    const [idEstablecimiento, setIdEstablecimiento] = useState<string | null>(null);

    useEffect(() => {
        if (open && data?.id && data?.fechaLectura === null) {
            setRead(data.id);
        }
    }, [open, data?.id, data?.fechaLectura, setRead]);

    useEffect(() => {
        if (data?.urlRedireccion) {
            const urlParts = data.urlRedireccion.split('/');
            const id = urlParts.pop();
        }
    }, [data, dispatch]);

    const storageValue = localStorage.getItem('persist:auth');
    const prefix = storageValue?.includes('BACKOFFICE')
        ? 'backoffice'
        : storageValue?.includes('SIMP')
        ? 'simp'
        : '';

    let urlRedirection = data?.urlRedireccion || '#';
    if (data?.urlRedireccion?.includes('/presentaciones')) {
        urlRedirection = `/${prefix}/establecimientos${data?.urlRedireccion
            .split('/')
            .slice(0, -1)
            .join('/')}`;
    } else {
        urlRedirection = `/${prefix}${data?.urlRedireccion.split('/').slice(0, -1).join('/')}`;
    }

    const handleRedirectClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (data?.establecimiento?.id) {
            dispatch(setEstablishmentIdFilter(Number(data?.establecimiento?.id)));
        }
        if (data?.establecimiento?.id) {
            dispatch(setEstablishmentId(Number(data?.establecimiento?.id)));
        }
        if (data?.establecimiento?.nombre) {
            dispatch(setEstablishmentName(data.establecimiento.nombre));
        }

        navigate(urlRedirection);

        onClose();
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} sx={{ zIndex: 1400 }}>
            <Box
                sx={{
                    width: 600,
                    padding: 3,
                    zIndex: 1400,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh'
                }}
            >
                <Grid container>
                    <DrawerHeader onIconClick={onClose} text={t('title')} />
                </Grid>

                <Box sx={{ flex: 1, p: 1, marginTop: 5 }}>
                    <Typography variant="h3" sx={{ marginTop: 2, fontSize: '24px' }}>
                        <strong> {data?.titulo || '-'}</strong>
                    </Typography>

                    <Typography variant="body2" sx={{ marginTop: -1, fontSize: '16px' }}>
                        {getDateTime(data?.fechaAlta) || '-'}
                    </Typography>

                    <Typography variant="body2" sx={{ marginTop: 3, fontSize: '18px' }}>
                        {data?.mensaje || '-'}
                    </Typography>

                    <Typography variant="body2" sx={{ marginTop: 2, fontSize: '18px' }}>
                        <Link
                            to={urlRedirection}
                            style={{
                                textDecoration: 'none',
                                color: themeLink.palette.primary.light
                            }}
                            onClick={handleRedirectClick}
                        >
                            {t('redirectEstablishment')}
                        </Link>
                    </Typography>
                </Box>

                <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{ alignSelf: 'flex-end', marginTop: 'auto' }}
                >
                    {t('goBack')}
                </Button>
            </Box>
        </Drawer>
    );
};

export default NotificationDrawer;
