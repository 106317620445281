import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ThemeState {
    theme: 'default' | 'green' | 'blue';
}

const initialState: ThemeState = {
    theme: 'default',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<'default' | 'green' | 'blue'>) => {
            state.theme = action.payload;
        },
    },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
