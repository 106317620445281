import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactElement } from 'react';
import styles from './styles.module.css';

type HeaderProps = {
    showTitle?: boolean;
    upperLabel?: string | any;
    lowerLabel?: string | any;
    titles?: any[];
    type: string;
    icon?: any;
    onClick?: any;
    template: (v: any, i?: any) => ReactElement;
    width?: string;
    align?: 'right' | 'left' | 'center' | 'justify' | 'char' | undefined;
    noStyle?: boolean;
};

interface Props {
    header: HeaderProps;
    item: any;
    index: number;
    onSelectRow: any;
    menuOpen: boolean;
    selected: any;
    selectedItemFromAction: any;
}

function DataTableCell({ header, item, index, menuOpen, selectedItemFromAction }: Props) {
    const isItemSelected = item?.id === selectedItemFromAction?.id;
    const DataCell = () => (
        <>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td align={header?.align || 'left'}>
                                {typeof header?.upperLabel === 'function'
                                    ? header?.upperLabel(item)
                                    : item[header?.upperLabel]}
                            </td>
                            <td align={header?.align || 'left'}>
                                {typeof header?.lowerLabel === 'function'
                                    ? header?.lowerLabel(item)
                                    : item[header?.lowerLabel]}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );

    const ActionCell = () => (
        <IconButton  className={`${styles['iconbtn-m']} ${menuOpen && isItemSelected ? styles['iconbtn-active'] : ''}`} aria-label="menuOpciones">
            {header?.icon ? header.icon : <KeyboardArrowDownIcon />}
        </IconButton>
    );

    const ElementCell = () => (
        <div>
            <table>
                <tbody>
                    <td>{header.template(item, index)}</td>
                </tbody>
            </table>
        </div>
    );

    const SelectionCell = () => (
        <IconButton className={styles['iconbtn-m']} aria-label="selectionCell">
            {header.template(item, index)}
        </IconButton>
    );

    function RenderTd() {
        switch (header?.type) {
            case 'selection':
                return <SelectionCell />;
            case 'action':
                return <ActionCell />;
            case 'element':
                return <SelectionCell />;
            case 'presentation-options':
                return <DataCell />;
            default:
                return <DataCell />;
        }
    }

    return <RenderTd />;
}

export default DataTableCell;
